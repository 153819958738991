/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://la3lvpcj3rhx5i4o6td5ffxrea.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-72l4pgcjdjb3bcg44ukjawyy5e',
  aws_cognito_identity_pool_id:
    'us-east-1:dbbd7dc4-eaa4-44c7-a5e8-995544f93d8f',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_p5eYYQ221',
  aws_user_pools_web_client_id: '5k04jpjb33nj002r2llpt6iao2',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 9,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket:
    'genepanelapi2330ac85120b4fcd9ec8b2bc20e2afac121329-int',
  aws_user_files_s3_bucket_region: 'us-east-1'
};

export default awsmobile;

import { Button, ButtonProps } from '@chakra-ui/react';
import { Paths } from 'routes';
import { FC } from 'react';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { DownloadCodebook } from './components';

interface ActionCellProps {
  isCompleted: boolean;
  panelId: string;
  hasCodebook: boolean;
  serialNumberTag: string;
}

export const ActionCell: FC<ActionCellProps> = ({
  isCompleted,
  panelId,
  hasCodebook,
  serialNumberTag
}) => {
  const buttonProps: ButtonProps = {
    variant: 'primaryGhost',
    maxW: '166px',
    minW: '166px',
    justifyContent: 'left',
    borderRadius: '0',
    padding: '0 12px',
    as: BrowserLink
  };

  return isCompleted ? (
    <DownloadCodebook
      panelId={panelId}
      hasCodebook={hasCodebook}
      serialNumberTag={serialNumberTag}
    />
  ) : (
    <Button
      {...buttonProps}
      to={generatePath(Paths.CreatePanel_Configuration, { panelId })}
      data-testid={`editPanelLink_${panelId}`}
    >
      Edit
    </Button>
  );
};

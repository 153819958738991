import { FC, RefObject, useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Link,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {
  AbundanceHeader,
  Row,
  SequentialNotification,
  ProbeHeader,
  StatusCell
} from './components';
import { Cell, TableInstance } from 'react-table';
import { ThreeDotsIcon, ErrorFilledSmallIcon } from 'assets';
import { PanelTranscriptStatus, PanelTranscriptType } from 'generated/types';
import { SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT } from 'consts';
import {
  TranscriptModel,
  useCreatePanelStore,
  transcriptsByTypeSelector
} from 'store';
import {
  TableTemplate,
  CustomColumn,
  GeneType,
  TooltipText
} from '@vizgen/vizgen-ui';
import { ConfirmGeneDeleteModal, GeneMenu } from 'components';
import { shallow } from 'zustand/shallow';

export enum CellType {
  Status = 'status',
  Type = 'type',
  SpecifiedName = 'userSpecifiedName',
  GeneName = 'geneName',
  Comment = 'comment',
  Abundance = 'abundance',
  Probe = 'auxProbes',
  Regions = 'targetRegionQty',
  Menu = 'menu',
  Selection = 'selection',
  Source = 'source'
}

const getOpacity = (value: PanelTranscriptStatus) => {
  return value === PanelTranscriptStatus.calculating ? 0.5 : 1;
};

const createTableColumns = (
  isPrimary: boolean
): CustomColumn<TranscriptModel>[] => {
  return [
    {
      Header: '',
      accessor: CellType.Status,
      cellProps: { p: '0px' },
      width: 40,
      isSortable: true,
      Cell: (cell: Cell<TranscriptModel, PanelTranscriptStatus>) => (
        <StatusCell isPrimary={isPrimary} cell={cell} />
      )
    },
    {
      Header: '',
      id: CellType.Source,
      accessor: CellType.Source,
      cellProps: { p: '0px' },
      width: 40,
      isSortable: true,
      Cell: (cell: Cell<TranscriptModel>) => {
        return (
          <Box h="100%" opacity={getOpacity(cell.row.original.status)}>
            <GeneType type={cell.value} />
          </Box>
        );
      }
    },
    {
      Header: 'Gene Input',
      accessor: CellType.SpecifiedName,
      width: 140,
      Cell: (cell: Cell<TranscriptModel, any>) => {
        return (
          <Box
            opacity={getOpacity(cell.row.original.status)}
            isTruncated
            maxW="116px"
          >
            <TooltipText>{cell.value}</TooltipText>
          </Box>
        );
      },
      isSortable: true
    },
    {
      Header: <>Identified Gene Name</>,
      accessor: CellType.GeneName,
      isSortable: true,
      width: 144,
      Cell: (cell: Cell<TranscriptModel, any>) => {
        return (
          cell.value && (
            <Box maxW="116px" isTruncated>
              <Link
                variant="underline"
                target="_blank"
                href={`https://www.ensembl.org/Multi/Search/Results?q=${cell.value}`}
              >
                <TooltipText
                  textProps={{
                    _hover: {
                      color: 'content.link.hover'
                    }
                  }}
                >
                  {cell.value}
                </TooltipText>
              </Link>
            </Box>
          )
        );
      }
    },
    {
      Header: 'Notes',
      accessor: CellType.Comment,
      width: 120,
      headerCellProps: {
        flex: '120 0 auto!important',
        w: '120px!important'
      },
      cellProps: {
        p: '0',
        h: '40px',
        flex: '120 0 auto!important',
        w: '120px!important'
      }
    },
    ...((isPrimary
      ? [
          {
            Header: () => <AbundanceHeader />,
            accessor: CellType.Abundance,
            isSortable: true,
            width: 174,
            cellProps: {
              p: '0',
              h: '40px',
              flex: '174 0 auto!important',
              w: '174px!important'
            },
            sortType: 'basic'
          }
        ]
      : [
          {
            Header: () => <ProbeHeader />,
            accessor: CellType.Probe,
            width: 174,
            cellProps: {
              p: '0',
              h: '40px',
              flex: '174 0 auto!important',
              w: '174px!important'
            }
          }
        ]) as CustomColumn<TranscriptModel>[]),
    {
      Header: <>Target Regions</>,
      accessor: CellType.Regions,
      isSortable: true,
      width: 116,
      Cell: (cell: Cell<TranscriptModel, number>) => {
        return (
          <Box
            opacity={getOpacity(cell.row.original.status)}
            maxW="92px"
            w="100%"
            textAlign="right"
          >
            {cell.value || '0'}
          </Box>
        );
      }
    },
    {
      id: CellType.Menu,
      // Here renders empty text in cell. Without it TDs dissapear.
      Header: '',
      cellProps: { p: '0px' },
      width: 32,
      Cell: (cell: Cell<TranscriptModel, any>) => {
        const { status, transcriptId, type, isIncluded } = cell.row.original;
        return (
          <GeneMenu
            isPrimary={isPrimary}
            view={status}
            transcriptId={transcriptId}
            type={type}
            isIncluded={isIncluded}
          >
            <div style={{ padding: '0 4px' }}>
              <ThreeDotsIcon />
            </div>
          </GeneMenu>
        );
      }
    }
  ];
};

interface GenesTableProps {
  tableRef?: RefObject<TableInstance<TranscriptModel>>;
  primaryTranscripts?: TranscriptModel[];
  isPrimary?: boolean;
  loadingProps?: {
    isVisible: boolean;
    text: string | React.ReactNode;
  };
}

const MAX_HEIGHT = 400;

export const GenesTable: FC<GenesTableProps> = ({
  primaryTranscripts = [],
  isPrimary = false,
  loadingProps,
  tableRef
}) => {
  const [
    setNotFoundErrorMessage,
    resetNotFoundErrorMessage,
    toggleTranscriptPanel
  ] = useCreatePanelStore(
    (state) => [
      state.setNotFoundErrorMessage,
      state.resetNotFoundErrorMessage,
      state.toggleTranscriptPanel
    ],
    shallow
  );
  const sequentialTranscripts = useCreatePanelStore(
    // Added memoization https://docs.pmnd.rs/zustand/recipes/recipes#memoizing-selectors
    // to avoid unnecessary recalculations/rerenders
    useCallback(
      (state) =>
        transcriptsByTypeSelector(PanelTranscriptType.sequential)(state),
      []
    )
  );
  const {
    isOpen: isDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
    onOpen: onDeleteConfirmOpen
  } = useDisclosure();
  const [isMovingDisabled, setIsMovingDisabled] = useState(false);
  const selectedRowsRef = useRef<TranscriptModel[]>([]);
  const isSequentialPanelOverloaded =
    sequentialTranscripts.length > SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT;

  const selectRowsCallback = (rows: TranscriptModel[]) => {
    if (rows.length !== selectedRowsRef.current.length) {
      selectedRowsRef.current = rows;
    }
    setIsMovingDisabled(
      isPrimary &&
        selectedRowsRef.current.length >
          SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT - sequentialTranscripts.length
    );
  };

  const togglePanel = () => {
    if (!isPrimary) {
      toggleTranscriptPanel(selectedRowsRef.current);
    } else {
      const notFounded = selectedRowsRef.current.filter(
        (row) => row.status === PanelTranscriptStatus.not_found
      );
      if (notFounded.length) {
        setNotFoundErrorMessage(notFounded);
      } else {
        resetNotFoundErrorMessage();
        toggleTranscriptPanel(selectedRowsRef.current);
      }
    }
  };

  const Buttons = () => {
    return (
      <HStack spacing="8px">
        <Button
          variant="tinyGhostPrimary"
          size="tiny"
          onClick={onDeleteConfirmOpen}
          data-testid="deleteGenesButton"
        >
          Delete selected
        </Button>
        <Button
          variant="tinyGhostPrimary"
          size="tiny"
          onClick={togglePanel}
          isDisabled={isMovingDisabled}
          data-testid={`moveTo${isPrimary ? 'Sequential' : 'Primary'}Button`}
        >
          Move to {isPrimary ? 'Sequential' : 'Primary'} Panel
        </Button>
      </HStack>
    );
  };

  const Title = () => {
    return (
      <HStack as="span">
        <Heading as="span" variant="subtitle">
          Sequential Panel
        </Heading>
        <Heading
          as="span"
          color={
            isSequentialPanelOverloaded ? 'state.error' : 'content.additional'
          }
          variant="subtitleSuffix"
          d="flex"
        >
          (
          {isSequentialPanelOverloaded && (
            <Box display="inline-block" mt="3px">
              <ErrorFilledSmallIcon />
            </Box>
          )}
          {sequentialTranscripts.length}/{SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT})
        </Heading>
      </HStack>
    );
  };

  const Placeholder = () =>
    !tableRef?.current?.flatRows?.length && !!tableRef?.current?.data.length ? (
      <Center>
        <Text color="content.additional" variant="bodyShort" mt="8px">
          No genes found
        </Text>
      </Center>
    ) : isPrimary ? (
      <Text color="content.additional" variant="bodyShort" mt="8px">
        Use MERSCOPE Panels, Custom Input or Exogenous Sequences on the left to
        add genes to the panel
      </Text>
    ) : (
      <Text color="content.additional" variant="bodyShort" mt="8px">
        You can add genes from MERFISH panel here
      </Text>
    );

  return (
    <>
      <TableTemplate
        tableName={`${isPrimary ? 'primary' : 'sequential'}Table`}
        ref={tableRef}
        columns={createTableColumns(isPrimary)}
        state={isPrimary ? primaryTranscripts : sequentialTranscripts}
        hasSortPlugin
        hasSelectPlugin
        hasSelectAll
        title={!isPrimary && <Title />}
        subtitle={!isPrimary && <SequentialNotification />}
        selectedRowsInfo={{
          text: ['gene selected', 'genes selected'],
          buttons: <Buttons />
        }}
        topBarProps={{
          css: {
            '& > *:nth-of-type(2)': {
              flex: 0
            }
          }
        }}
        maxHeight={MAX_HEIGHT}
        renderRow={(row, style) => <Row row={row} style={style} />}
        loadingProps={loadingProps}
        selectedRowsCallback={selectRowsCallback}
        placeholder={<Placeholder />}
        hasGlobalFilterPlugin
      />
      <ConfirmGeneDeleteModal
        isVisible={isDeleteConfirmOpen}
        isPrimary={isPrimary}
        transcriptIds={selectedRowsRef.current.map((row) => row.transcriptId)}
        onClose={onDeleteConfirmClose}
      />
    </>
  );
};

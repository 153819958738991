import { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  VStack,
  Flex,
  Heading,
  Text,
  Button,
  IconButton
} from '@chakra-ui/react';
import { CloseIcon } from './assets';
import { FormState } from '../../hooks';
import { PanelFormData, useCreatePanelStore } from 'store';
import { Panel } from 'generated/types';
import { shallow } from 'zustand/shallow';

interface ConfirmLeavingModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  state: FormState;
  formData: PanelFormData;
  sourcePanel: Panel | null;
}

export const ConfirmLeavingModal: FC<ConfirmLeavingModalProps> = ({
  onCancel,
  onConfirm,
  state,
  formData,
  sourcePanel
}) => {
  const [addGenesFromSourcePanel, createPanel, updatePanel, isPanelUpdating] =
    useCreatePanelStore(
      (state) => [
        state.addGenesFromSourcePanel,
        state.createPanel,
        state.updatePanel,
        state.isPanelUpdating
      ],
      shallow
    );

  let title = '';
  let text = '';
  let cancelButtonText = '';
  let confirmButtonText = '';
  let cancel = () => {};
  let confirm = () => {};
  const missingData = [
    formData.panelName ? '' : 'panel name',
    formData.reference ? '' : 'reference',
    formData.tissue ? '' : 'tissue'
  ]
    .filter((v) => v)
    .join(', ');

  switch (state) {
    case FormState.ExistingUnsaved: {
      title = 'Save changes before leaving?';
      text =
        'You are about to leave this page without saving. All changes will be lost';
      cancelButtonText = 'Discard Changes';
      confirmButtonText = 'Save';
      cancel = onConfirm;
      confirm = async () => {
        await updatePanel(formData);
        onConfirm();
      };
      break;
    }
    case FormState.NewUnsaved: {
      title = 'Save panel before leaving?';
      text = `The data will be lost if you don’t save the panel before closing the page.\n\nAlso the panel is saved automatically when you proceed to the next step`;
      cancelButtonText = 'Don’t Save';
      confirmButtonText = 'Save';
      cancel = onConfirm;
      confirm = async () => {
        const id = await createPanel(formData);
        await addGenesFromSourcePanel(sourcePanel, id);
        onConfirm();
      };
      break;
    }
    case FormState.Invalid: {
      title = 'Leave page?';
      text = `You did not provide the ${missingData}. All data you entered will be lost if you leave the page now.\n\nTo save the panel please provide ${missingData}`;
      cancelButtonText = 'Leave Page';
      confirmButtonText = 'Continue Editing';
      cancel = onConfirm;
      confirm = onCancel;
      break;
    }
  }

  return (
    <Modal isOpen={!!state} autoFocus isCentered onClose={onCancel}>
      <ModalOverlay />
      <ModalContent
        p="24px 36px"
        width="100%"
        maxW="552px"
        borderRadius="16px"
        m="0"
      >
        <VStack spacing="40px" w="100%" alignItems="flex-start">
          <Flex w="100%" justify="space-between">
            <Heading variant="subtitle" fontWeight="heavy">
              {title}
            </Heading>
            <IconButton
              onClick={onCancel}
              variant="primaryGhost"
              size="icon"
              aria-label="Close modal"
              data-testid="closeModalButton"
              icon={<CloseIcon />}
            />
          </Flex>
          <Text whiteSpace="pre-wrap">{text}</Text>
          <Flex w="100%" justify="end">
            <Button
              variant="secondarySolid"
              mr="16px"
              data-testid="cancelButton"
              disabled={isPanelUpdating}
              onClick={cancel}
            >
              {cancelButtonText}
            </Button>
            <Button
              textTransform="none"
              data-testid="confirmButton"
              isLoading={isPanelUpdating}
              onClick={confirm}
            >
              {confirmButtonText}
            </Button>
          </Flex>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

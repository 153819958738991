import { useMemo } from 'react';
import { Resource } from 'models';
import { DEFAULT_PRODUCTS_OPTION } from 'consts';
import { PRODUCT_LABELS } from '@vizgen/vizgen-ui';

export const useOptions = (resources: Resource[]) => {
  return useMemo(() => {
    const options = resources
      .map((resource) => resource.product)
      .filter((product, index, arr) => arr.indexOf(product) === index)
      .map((product) => ({
        value: product,
        label: PRODUCT_LABELS[product]
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));
    return [DEFAULT_PRODUCTS_OPTION, ...options];
  }, [resources]);
};

import { FC } from 'react';
import { NotificationType } from '../../types';
import ErrorIcon from './assets/errorIcon.svg';
import InfoIcon from './assets/infoIcon.svg';
import WarningIcon from './assets/warningIcon.svg';

interface IconProps {
  type: NotificationType;
  size: string;
}

export const Icon: FC<IconProps> = ({ type, size }) => {
  const props: React.SVGAttributes<SVGElement> = {
    width: size,
    height: size
  };

  switch (type) {
    case NotificationType.Error:
      return <ErrorIcon {...props} />;
    case NotificationType.Warning:
      return <WarningIcon {...props} />;
    case NotificationType.Info:
      return <InfoIcon {...props} />;
  }
};

import {
  Input,
  InputProps,
  FormControlProps,
  FormErrorMessageProps,
  InputGroup,
  InputLeftElement,
  forwardRef,
  InputElementProps,
  InputRightElement,
  Spinner
} from '@chakra-ui/react';
import { BaseField } from '../BaseField';
import SearchIcon from './assets/searchIcon.svg';
import ClearIcon from './assets/clear.svg';

export interface SearchFieldProps extends InputProps {
  name: string;
  error?: string;
  controlProps?: FormControlProps;
  errorProps?: FormErrorMessageProps;
  isRightElementShown?: boolean;
  isLeftElementShown?: boolean;
  rightElementProps?: InputElementProps;
  isLoading?: boolean;
}

export const SearchField = forwardRef<SearchFieldProps, 'input'>(
  (props, ref) => {
    const {
      error,
      controlProps,
      errorProps,
      isRightElementShown,
      isLeftElementShown = true,
      rightElementProps,
      isLoading,
      ...rest
    } = props;
    return (
      <BaseField
        controlProps={controlProps}
        errorProps={errorProps}
        id={rest.name}
        error={error}
      >
        <InputGroup>
          {isLeftElementShown && (
            <InputLeftElement
              data-testid="searchIcon"
              pointerEvents="none"
              children={<SearchIcon />}
              h="100%"
              w="40px"
              zIndex="1"
              opacity={rest.isDisabled ? 0.5 : 1}
            />
          )}
          <Input
            ref={ref}
            variant="search"
            size="smSearch"
            pl={isLeftElementShown ? '40px' : '12px'}
            data-testid={rest.name}
            {...rest}
          />
          {isRightElementShown && !isLoading && !rest.isDisabled && (
            <InputRightElement
              data-testid="clearSearch"
              children={<ClearIcon />}
              cursor="pointer"
              h="100%"
              w="40px"
              {...rightElementProps}
            />
          )}
          {isLoading && (
            <InputRightElement
              data-testid="loading"
              children={<Spinner w="24px" h="24px" />}
              h="100%"
              w="48px"
              {...rightElementProps}
            />
          )}
        </InputGroup>
      </BaseField>
    );
  }
);

import {
  Button,
  ButtonProps,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { useScrollPosition, useIsOverflow } from './hooks';
import CloseIcon from './assets/closeIcon.svg';

export interface MiniModalProps {
  title?: string;
  submitText?: string;
  cancelText?: string;
  isInProgress?: boolean;
  isSubmitDisabled?: boolean;
  isSubmitHidden?: boolean;
  isCancelHidden?: boolean;
  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  footerProps?: ModalFooterProps;
  modalProps?: Partial<ModalProps>;
  onClose: () => void;
  onCancel?: () => void;
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  onSubmit?: () => Promise<void> | void;
}

export const MiniModal: FC<MiniModalProps> = ({
  title,
  cancelText = 'Cancel',
  submitText,
  isInProgress,
  isSubmitDisabled,
  isSubmitHidden,
  isCancelHidden,
  contentProps,
  headerProps,
  bodyProps,
  footerProps,
  modalProps,
  children,
  renderHeader,
  renderFooter,
  onSubmit,
  onClose,
  onCancel
}) => {
  const [ref, setRef] = useState();
  const isOverflow = useIsOverflow(ref);
  const { isBottomReached, isTopReached } = useScrollPosition(ref, isOverflow);
  const submitButtonProps: ButtonProps = contentProps?.onSubmit
    ? { type: 'submit' }
    : { onClick: onSubmit };

  const handleRef = useCallback((node) => {
    setRef(node);
  }, []);

  return (
    <Modal
      isOpen
      autoFocus
      isCentered
      closeOnOverlayClick={!isInProgress}
      closeOnEsc={!isInProgress}
      scrollBehavior="inside"
      variant="mini"
      onClose={onClose}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent
        bg="background.secondary"
        width="100%"
        maxW="368px"
        maxH="100%"
        borderRadius="8px"
        as="form"
        {...contentProps}
      >
        <ModalHeader
          borderBottom="1px solid"
          borderColor={isTopReached ? 'background.secondary' : 'border.divider'}
          p="16px 24px"
          {...headerProps}
        >
          {renderHeader ? (
            renderHeader()
          ) : (
            <Flex w="100%" justify="space-between">
              <Heading variant="subtitle">{title}</Heading>
              <IconButton
                onClick={onClose}
                variant="primaryGhost"
                size="icon"
                aria-label="Close modal"
                data-testid="closeModalButton"
                isDisabled={isInProgress}
                icon={<CloseIcon />}
              />
            </Flex>
          )}
        </ModalHeader>
        <ModalBody p="8px 24px 16px 24px" ref={handleRef} {...bodyProps}>
          {children}
        </ModalBody>
        <ModalFooter
          p="16px 0"
          borderTop="1px solid"
          borderColor={
            isBottomReached ? 'background.secondary' : 'border.divider'
          }
          {...footerProps}
        >
          {renderFooter ? (
            renderFooter()
          ) : (
            <HStack w="100%" justify="center" spacing="14px">
              {isCancelHidden || (
                <Button
                  variant="primaryGhost"
                  bg="background.primary"
                  data-testid="closeButton"
                  disabled={isInProgress}
                  onClick={onCancel || onClose}
                  maxW="80px"
                  minW="80px"
                >
                  {cancelText}
                </Button>
              )}
              {isSubmitHidden || (
                <Button
                  variant="secondarySolid"
                  data-testid="confirmButton"
                  disabled={isInProgress || isSubmitDisabled}
                  isLoading={isInProgress}
                  {...submitButtonProps}
                >
                  {submitText}
                </Button>
              )}
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

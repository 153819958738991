import { FC, RefObject } from 'react';
import {
  CustomColumn,
  TableTemplate,
  TablePlaceholder
} from '@vizgen/vizgen-ui';
import { Quote } from 'api/types';
import { TableInstance } from 'react-table';
import { LinkCell } from './components';
import { sortByDate, formatDateTime } from '@vizgen/vizgen-ui';

const getTableColumns = (isShort: boolean): CustomColumn<Quote>[] => {
  return [
    {
      Header: 'Quote Number',
      accessor: 'quoteId',
      isSortable: !isShort,
      columnMaxW: 360,
      width: '100%',
      cellProps: { p: '0' },
      Cell: (cell) => {
        return <LinkCell quoteId={cell.value}>{cell.value}</LinkCell>;
      }
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      isSortable: !isShort,
      columnMaxW: 192,
      width: '100%',
      cellProps: { p: '0' },
      Cell: (cell) => {
        const { quoteId } = cell.row.original;
        return (
          <LinkCell quoteId={quoteId}>{formatDateTime(cell.value)}</LinkCell>
        );
      },
      sortType: sortByDate
    },
    {
      Header: 'Panel(s)',
      accessor: (row) =>
        row.quotepositions?.items
          .filter((position) => !!position.panel)
          ?.map((position) => position?.panel?.panelName)
          .join(', '),
      cellProps: {
        p: '0'
      },
      columnMaxW: 574,
      width: '100%',
      isSortable: !isShort,
      Cell: (cell: any) => {
        const { quoteId } = cell.row.original;
        return <LinkCell quoteId={quoteId}>{cell.value}</LinkCell>;
      }
    }
  ];
};

interface QuotesTableProps {
  quotesList: any[];
  hasGlobalFilterPlugin?: boolean;
  hasFilterPlugin?: boolean;
  isShort?: boolean;
  tableRef?: RefObject<TableInstance<Quote>>;
  maxHeight?: number;
  isLoading: boolean;
}

export const QuotesTable: FC<QuotesTableProps> = ({
  quotesList,
  hasGlobalFilterPlugin = false,
  isShort = false,
  tableRef,
  hasFilterPlugin = false,
  maxHeight,
  isLoading
}) => {
  const placeholder = isLoading ? 'Loading...' : 'No quotes found';

  return (
    <TableTemplate
      tableName="quotesTable"
      maxHeight={maxHeight}
      ref={tableRef}
      columns={getTableColumns(isShort)}
      state={quotesList}
      wrapperProps={{
        d: 'flex',
        overflowY: 'hidden',
        height: '100%',
        flexDirection: 'column',
        maxW: '1128px',
        w: '100%'
      }}
      hasSortPlugin
      hasFilterPlugin={hasFilterPlugin}
      hasGlobalFilterPlugin={hasGlobalFilterPlugin}
      placeholder={<TablePlaceholder>{placeholder}</TablePlaceholder>}
    />
  );
};

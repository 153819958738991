import { FC, Fragment, useEffect } from 'react';
import { HStack, Text, Flex, Box } from '@chakra-ui/react';
import {
  DefaultItemType,
  Dropdown,
  Tooltip,
  ValidationMessage
} from '@vizgen/vizgen-ui';
import { Control, FieldValues, useController } from 'react-hook-form';
import { ReactComponent as LockedIcon } from 'assets/locked.svg';
import { ChemistryValidation } from '../../useChemistryValidation';

interface ChemistryVersionProps {
  control: Control<FieldValues>;
  isDisabled: boolean;
  defaultValue: DefaultItemType | null;
  validation: ChemistryValidation;
}

export const MERFISH_VERSIONS: DefaultItemType[] = [
  {
    label: 'MERFISH 1.0',
    value: '1'
  },
  {
    label: 'MERFISH 2.0',
    value: '2'
  }
];

export const ChemistryVersion: FC<ChemistryVersionProps> = ({
  control,
  isDisabled,
  defaultValue,
  validation: { isLocked, icon }
}) => {
  const {
    field: { onChange, name, value },
    fieldState: { invalid }
  } = useController({
    name: 'merfishVersion',
    control,
    rules: { required: true },
    defaultValue
  });
  const TooltipComponent = isLocked ? Tooltip : Fragment;
  const tooltipProps = isLocked
    ? { label: 'Creation of MERFISH 1.0 panels is disabled.' }
    : {};

  const onChangeHandler = (e: DefaultItemType | null) => {
    onChange(e);
  };

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]); // eslint-disable-line

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Flex direction="column" maxW={264} w="100%" pt="12px">
        <TooltipComponent {...tooltipProps}>
          <Flex direction="row">
            <Text mr="8px">Chemistry version</Text>
            {isLocked && <LockedIcon />}
          </Flex>
        </TooltipComponent>
        <Text color="content.additional" variant="captionShort">
          MERFISH 1.0 and MERFISH 2.0 chemistry versions require different panel
          designs and reagents. For more information contact Vizgen technical
          support.
        </Text>
      </Flex>
      <HStack spacing="16px" w="100%">
        <Box maxW={360} w="100%" pos="relative">
          <Dropdown
            data-testid="select-chemistry-version"
            items={MERFISH_VERSIONS}
            defaultValue={value}
            value={value}
            onChange={onChangeHandler}
            valueKey="value"
            labelKey="label"
            name={name}
            isDisabled={isDisabled || isLocked}
            placeholder="Choose chemistry version"
            isInvalid={invalid}
          />
          {invalid && (
            <ValidationMessage>Fill the required field</ValidationMessage>
          )}
        </Box>
        {icon}
      </HStack>
    </HStack>
  );
};

import {
  Flex,
  HStack,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { CustomTab, PanelGenes } from '@vizgen/vizgen-ui';
import { FC, useState } from 'react';
import { TranscriptModel } from 'store';
import { getIsManualPanel } from 'utils';
import { PanelCompositionTab } from './components';

const tabPanelStyles: TabPanelProps = {
  p: '0',
  h: '100%',
  d: 'flex',
  flexDirection: 'column',
  w: '100%'
};

interface PanelTabsProps {
  transcriptomeId: string;
  primaryTranscripts: TranscriptModel[];
  sequentialTranscripts: TranscriptModel[];
  serialNumber: string;
}

export const PanelTabs: FC<PanelTabsProps> = ({
  transcriptomeId,
  primaryTranscripts,
  sequentialTranscripts,
  serialNumber
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!primaryTranscripts.length && !sequentialTranscripts.length) {
    return null;
  }

  return (
    <Tabs
      isLazy
      lazyBehavior="keepMounted"
      variant="unstyled"
      w="100%"
      h="100%"
      bg="background.primary"
      d="flex"
      flexDirection="column"
      pos="relative"
      alignItems="center"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <Flex w="100%" justify="center">
        <TabList pos="relative" maxW="1128px" w="100%" h="48px">
          <HStack spacing="24px">
            <CustomTab h="100%" data-testid="geneListButton">
              Gene List
            </CustomTab>
            <CustomTab
              h="100%"
              data-testid="panelCompositionButton"
              isDisabled={getIsManualPanel(serialNumber)}
            >
              Panel Composition
            </CustomTab>
          </HStack>
        </TabList>
      </Flex>
      <TabPanels
        pt="32px"
        h="100%"
        d="flex"
        flexDirection="column"
        alignItems="center"
      >
        <TabPanel {...tabPanelStyles}>
          <PanelGenes
            isHeadingHidden
            primaryTranscripts={primaryTranscripts}
            sequentialTranscripts={sequentialTranscripts}
          />
        </TabPanel>
        <TabPanel {...tabPanelStyles}>
          <PanelCompositionTab
            transcriptomeId={transcriptomeId}
            transcripts={[...primaryTranscripts, ...sequentialTranscripts]}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

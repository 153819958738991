import { Box, BoxProps, HStack, MenuButton } from '@chakra-ui/react';
import { FC } from 'react';
import { theme } from '../../theme';
import UserIcon from './assets/user.svg';
const { colors } = theme;

interface ProfileButtonProps {
  name: string;
  textBoxProps?: BoxProps;
}

export const ProfileButton: FC<ProfileButtonProps> = ({
  name,
  textBoxProps
}) => {
  return (
    <MenuButton
      borderRadius="4px"
      p="12px"
      bg="button.ghostOnPrimary.normal"
      color="content.additional"
      _hover={{
        bg: 'button.ghostOnPrimary.hover',
        color: 'content.secondary'
      }}
      _active={{
        bg: 'button.ghostOnPrimary.pressed',
        color: 'content.secondary',
        path: {
          stroke: colors.content.secondary
        }
      }}
      css={{
        '&:hover path': {
          transition: 'all 250ms ease-in-out',
          stroke: colors.content.secondary
        }
      }}
      data-testid="userMenuHeaderButton"
    >
      <HStack spacing="4px">
        <Box>
          <UserIcon />
        </Box>
        <Box textAlign="left" {...textBoxProps}>
          {name}
        </Box>
      </HStack>
    </MenuButton>
  );
};

import { FC } from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import {
  ContainerLayout,
  PanelConfiguration,
  PanelDetailsList,
  Navigation,
  Notifications
} from 'components';
import { Link as RouterLink } from 'react-router-dom';
import { useGetPanel } from 'hooks';
import {
  auxProbesSelector,
  isPanelCompletedSelector,
  MessageType,
  useCreatePanelStore,
  useFeatureFlagsStore,
  useNotificationsStore
} from 'store';
import {
  useGetTissue,
  useGetReference,
  useGetPanelConfiguration,
  useImagingKit
} from 'hooks';
import { downloadCodebook, hasIncompatibleChemistryVersion } from 'utils';
import { Link as BrowserLink } from 'react-router-dom';
import { generatePath, useParams } from 'react-router';
import { ConfirmFinalizationModal } from './components';
import { Header } from '../Header';
import { Paths } from 'routes';
import { useFailureNotification, formatErrorMessages } from '@vizgen/vizgen-ui';
import { Panel, PanelOwnershipType } from 'generated/types';

export const SummaryStep: FC = () => {
  useGetPanel();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const panel = useCreatePanelStore((state) => state.panel) as Panel;
  const isCompleted = useCreatePanelStore(isPanelCompletedSelector);
  const auxProbes = useCreatePanelStore(auxProbesSelector);
  const tissue = useGetTissue();
  const reference = useGetReference();
  const panelConfiguration = useGetPanelConfiguration();
  const { panelId } = useParams<{ panelId: string }>();
  const kit = useImagingKit(panel?.genesSummary?.primary || 0);
  const setNotifications = useNotificationsStore(
    (state) => state.setNotifications
  );
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  const download = async () => {
    try {
      closeFailureNotifications();
      await downloadCodebook(panel?.panelId || '');
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  const finalize = async () => {
    try {
      closeFailureNotifications();
      if (gm201) {
        const hasError = await hasIncompatibleChemistryVersion(
          panel.merfishVersion
        );
        setNotifications([
          {
            message:
              'Creation of MERFISH 1.0 panels is disabled. Please return to the “Configure Panel” screen and change the panel to MERFISH 2.0.',
            type: MessageType.UnacceptableChemistryVersion,
            isVisible: hasError
          }
        ]);
        if (hasError) {
          return;
        }
      }
      onOpen();
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <>
      <Header currentStep={2} />
      <ContainerLayout>
        <Box maxW="632px" pb="32px">
          <PanelConfiguration items={panelConfiguration} />
          <VStack w="100%" align="start" spacing="32px">
            <Heading as="h1">Summary</Heading>
            <Notifications />
            <PanelDetailsList
              panel={panel}
              tissue={tissue}
              reference={reference}
              auxProbes={auxProbes}
              matchingKit={kit}
            />
          </VStack>
          {isCompleted && (
            <HStack spacing="24px" mb="32px">
              <Button
                variant="primaryHigh"
                w="50%"
                isDisabled={!panel?.hasCodebook && !panel?.serialNumberTag}
                onClick={download}
                data-testid="downloadCodebookButton"
              >
                Download Codebook
              </Button>
              <Button
                variant="primaryGhost"
                w="50%"
                h="48px"
                as={BrowserLink}
                target="_blank"
                to={generatePath(Paths.PrintablePanel, {
                  panelType: PanelOwnershipType.proprietary,
                  panelId: panel?.panelId
                })}
                data-testid="openPrintableFileLink"
              >
                Open Printable File
              </Button>
            </HStack>
          )}
        </Box>
      </ContainerLayout>
      <Navigation
        leftButton={
          isCompleted ? undefined : (
            <Button
              as={RouterLink}
              to={generatePath(Paths.CreatePanel_AddGenes, { panelId })}
              variant="ghost"
              px="5"
              data-testid="backLink"
            >
              Back
            </Button>
          )
        }
        rightButton={
          isCompleted ? (
            <Button
              as={BrowserLink}
              to={generatePath(Paths.NewQuoteForPanel, { panelId })}
              px="8"
              variant="secondarySolid"
              data-testid="requestQuoteLink"
            >
              Request Quote
            </Button>
          ) : (
            <Button
              onClick={finalize}
              px="8"
              variant="secondarySolid"
              data-testid="finalizePanelButton"
            >
              Finalize Panel
            </Button>
          )
        }
      />
      <ConfirmFinalizationModal
        kit={kit}
        isVisible={isOpen}
        onClose={onClose}
      />
    </>
  );
};

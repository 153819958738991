import { FC } from 'react';
import { ToastNotificationType } from '../../types';
import CheckIcon from './assets/check.svg';
import ErrorIcon from './assets/error.svg';

interface StatusIconProps {
  type: ToastNotificationType;
}

export const StatusIcon: FC<StatusIconProps> = ({ type }) => {
  switch (type) {
    case ToastNotificationType.Success:
      return <CheckIcon />;
    case ToastNotificationType.Error:
      return <ErrorIcon />;
    default:
      return null;
  }
};

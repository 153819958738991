import { Box, Container, Heading, Link, VStack } from '@chakra-ui/react';
import { Content, PanelDetailsList, PanelLoader } from 'components';
import { FC } from 'react';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { TranscriptModel } from 'store';
import {
  PanelQuotes,
  ButtonsBar,
  PanelStatus,
  PanelTabs,
  GenesHeading
} from './components';
import { useImagingKit, usePanelSpecifications } from 'hooks';
import { Paths } from 'routes';
import {
  PanelTranscriptType,
  Panel,
  PanelOwnershipType
} from 'generated/types';
import { getIsManualPanel } from 'utils';

interface PanelDetailsProps {
  panel?: Panel;
  transcripts?: TranscriptModel[];
}

const filterTranscripts = (
  type: PanelTranscriptType,
  transcripts?: TranscriptModel[]
) => transcripts?.filter((transcript) => transcript?.type === type) || [];

export const PanelDetails: FC<PanelDetailsProps> = ({ panel, transcripts }) => {
  const { reference, tissue } = usePanelSpecifications(panel);
  const kit = useImagingKit(panel?.genesSummary?.primary || 0);
  const isPromoted = panel?.ownershipType === PanelOwnershipType.promoted;
  const isManualPanel = getIsManualPanel(panel?.serialNumberTag);
  const panelLabel = isManualPanel
    ? 'Manual'
    : isPromoted
    ? 'MERSCOPE'
    : 'Custom';

  const primaryTranscripts = filterTranscripts(
    PanelTranscriptType.primary,
    transcripts
  );
  const sequentialTranscripts = filterTranscripts(
    PanelTranscriptType.sequential,
    transcripts
  );
  const auxProbes = sequentialTranscripts
    .filter((transcript) => transcript.auxProbes)
    .map((transcript) => transcript.auxProbes)
    .sort()
    .join(', ');

  if (!panel || !transcripts) {
    return <PanelLoader />;
  }

  return (
    <Content>
      <Box p="24px 60px 40px">
        <Container maxW="1128px" p="0">
          <Link
            variant="primary"
            size="md"
            as={BrowserLink}
            to={generatePath(Paths.Panels + '/:panelType', {
              panelType: isManualPanel
                ? 'manual'
                : isPromoted
                ? PanelOwnershipType.promoted
                : PanelOwnershipType.proprietary
            })}
            data-testid={`${panelLabel.toLowerCase()}PanelsLink`}
          >
            {panelLabel} Panels
          </Link>
          <Box>
            <VStack spacing="12px" mt="20px" alignItems="start">
              <Heading variant="headline">{panel.panelName}</Heading>
              <ButtonsBar panel={panel} transcripts={transcripts} />
            </VStack>
            <Box maxW="744px">
              <PanelStatus panel={panel} />
              <Box mt="32px">
                <PanelDetailsList
                  panel={panel}
                  auxProbes={auxProbes}
                  tissue={tissue}
                  reference={reference}
                  isCommentTruncated={false}
                  matchingKit={panel.isCompleted ? kit : undefined}
                />
              </Box>
            </Box>
            <VStack spacing="40px">
              <PanelQuotes isCompleted={panel.isCompleted || false} />
              <VStack spacing="24px" w="100%">
                <GenesHeading transcripts={transcripts} />
                <PanelTabs
                  transcriptomeId={panel.transcriptomeId}
                  primaryTranscripts={primaryTranscripts}
                  sequentialTranscripts={sequentialTranscripts}
                  serialNumber={panel.serialNumberTag || ''}
                />
              </VStack>
            </VStack>
          </Box>
        </Container>
      </Box>
    </Content>
  );
};

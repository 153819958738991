import { VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { ResourceItem, NotFoundMessage } from 'components';
import { Resource } from 'models';
import { Category, CATEGORY_LABELS, Scrollbar } from '@vizgen/vizgen-ui';

interface ResourcesListProps {
  resources: Resource[];
  category: Category;
}

export const ResourcesList: FC<ResourcesListProps> = ({
  resources,
  category
}) => {
  const isSoftware = category === Category.Software;

  if (!resources.length) {
    return (
      <NotFoundMessage>
        We didn’t find any {CATEGORY_LABELS[category].toLowerCase()} matching
        your search request
      </NotFoundMessage>
    );
  }

  return (
    <Scrollbar>
      <VStack spacing="16px" align="start">
        {resources.map((resource) => (
          <ResourceItem
            key={resource.resourceId}
            resource={resource}
            isSoftware={isSoftware}
          />
        ))}
      </VStack>
    </Scrollbar>
  );
};

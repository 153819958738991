import { FC } from 'react';
import { Box, HStack, Stack, Text, Heading } from '@chakra-ui/react';
import { Quote } from 'api/types';
import { formatDateTime } from '@vizgen/vizgen-ui';

interface TopbarProps {
  quote: Quote;
  totalAmount: number;
}

export const Topbar: FC<TopbarProps> = ({ quote, totalAmount }) => {
  return (
    <Stack spacing="16px" mb="16px">
      <Heading variant="headline">
        {quote.quoteId}{' '}
        <Box as="span" fontWeight="normal" color="content.additional">
          ({totalAmount} items)
        </Box>
      </Heading>
      <HStack spacing="30px">
        <Text>
          <Box as="span" fontWeight="heavy" color="content.primary">
            Last Updated:
          </Box>{' '}
          {formatDateTime(quote.updatedAt)}
        </Text>
        <Text>
          <Box as="span" fontWeight="heavy" color="content.primary">
            Status:
          </Box>{' '}
          {quote.Status}
        </Text>
      </HStack>
    </Stack>
  );
};

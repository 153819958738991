import { FC } from 'react';
import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { StatusIcon } from './components';
import { ToastNotificationType } from './types';

export interface ToastNotificationProps {
  type: ToastNotificationType;
  text: string;
  onClose?: () => void;
}

const BACKGROUND_COLOR: Record<ToastNotificationType, string> = {
  [ToastNotificationType.Success]: 'state.success',
  [ToastNotificationType.Error]: 'state.error'
};

export const ToastNotification: FC<ToastNotificationProps> = ({
  type,
  text,
  onClose
}) => {
  return (
    <Flex
      data-testid="notificationContainer"
      w="100%"
      justify="center"
      bg={BACKGROUND_COLOR[type]}
    >
      <Flex w="100%" minH="64px" maxW="1440px" p="0 60px" alignItems="center">
        <HStack spacing="4px" w="100%">
          <Box w="24px">
            <StatusIcon type={type} />
          </Box>
          <Text py="8px" color="background.primary">
            {text}
          </Text>
        </HStack>
        {onClose && (
          <Button
            ml="16px"
            variant="secondarySolid"
            size="tiny"
            onClick={onClose}
          >
            Dismiss
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

import { useCustomPanelsListQuery, usePromotedPanelListQuery } from 'api/hooks';
import dayjs from 'dayjs';
import { Panel } from 'generated/types';
import { PersonalGenePanelModel } from 'models';
import { useGlobalListsStore } from 'store';

const formatPanelData = (
  references: {
    transcriptomeId: string;
    name: string;
  }[],
  panel: Panel
) => ({
  ref:
    references.find((ref) => ref.transcriptomeId === panel.transcriptomeId)
      ?.name ||
    panel.transcriptomeId ||
    '',
  updatedAt: dayjs(panel.updatedAt).valueOf()
});

export const useGetPanels = () => {
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const { data: custom = [], isFetched: isCustomFetched } =
    useCustomPanelsListQuery();
  const { data: promoted = [], isFetched: isPromotedFetched } =
    usePromotedPanelListQuery();
  const isLoading = !isCustomFetched || !isPromotedFetched;

  return {
    panels: isLoading
      ? []
      : ([...custom, ...promoted]
          .map((panel) => ({
            ...panel,
            ...formatPanelData(references, panel as Panel)
          }))
          .sort(
            (a, b) => b.updatedAt - a.updatedAt
          ) as PersonalGenePanelModel[]),
    isLoading
  };
};

import { StackProps, VStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useNotificationsStore, NotificationLevel } from 'store';
import { shallow } from 'zustand/shallow';
import { Notification, NotificationType } from '@vizgen/vizgen-ui';

interface NotificationsProps {
  isCloseAllowed?: boolean;
  containerProps?: StackProps;
}

export const Notifications: FC<NotificationsProps> = ({
  isCloseAllowed = true,
  containerProps,
  children
}) => {
  const [
    notifications,
    getErrors,
    getWarnings,
    getInfo,
    clearNotifications,
    clearState
  ] = useNotificationsStore(
    (state) => [
      state.notifications,
      state.getErrors,
      state.getWarnings,
      state.getInfo,
      state.clearNotifications,
      state.clearState
    ],
    shallow
  );
  const errors = getErrors();
  const warnings = getWarnings();
  const infos = getInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, []);

  if (!notifications.length) {
    return <>{children}</>;
  }

  return (
    <VStack
      spacing="8px"
      maxW="840px"
      mb="20px"
      mr="60px"
      w="100%"
      {...containerProps}
    >
      <Notification
        type={NotificationType.Error}
        messages={errors}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.ERROR)}
      />
      <Notification
        type={NotificationType.Warning}
        messages={warnings}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.WARN)}
      />
      <Notification
        type={NotificationType.Info}
        messages={infos}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.INFO)}
      />
    </VStack>
  );
};

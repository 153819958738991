import {
  Button,
  Center,
  Divider,
  HStack,
  useDisclosure
} from '@chakra-ui/react';
import { ConfirmDeletePanelModal } from 'components';
import { FC } from 'react';
import {
  generatePath,
  Link as BrowserLink,
  useNavigate
} from 'react-router-dom';
import { Panel, PanelOwnershipType } from 'generated/types';
import { TranscriptModel } from 'store';
import { downloadGenesList, getIsManualPanel } from 'utils';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';
import { Paths } from 'routes';

const ButtonsDivider = () => (
  <Center height="32px">
    <Divider orientation="vertical" />
  </Center>
);

interface ButtonsBarProps {
  panel: Panel;
  transcripts: TranscriptModel[];
}

export const ButtonsBar: FC<ButtonsBarProps> = ({ panel, transcripts }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasGenes = transcripts.length > 0;
  const { panelId, panelName, serialNumberTag, ownershipType } = panel;
  const isPromoted = ownershipType === PanelOwnershipType.promoted;
  const isCompleted = panel.isCompleted || false;
  const isManualPanel = getIsManualPanel(serialNumberTag);

  const DownloadGeneListButton = () => (
    <Button
      variant="primaryGhost"
      h="40px"
      minW="auto"
      onClick={() =>
        downloadGenesList(
          isCompleted,
          serialNumberTag || '',
          panelName,
          transcripts
        )
      }
      data-testid="downloadGeneListButton"
    >
      Download Gene List
    </Button>
  );

  return (
    <>
      <HStack spacing="24px" w="100%" justify="end">
        {isCompleted ? (
          <>
            {isManualPanel || (
              <Button
                variant="primaryGhost"
                h="40px"
                as={BrowserLink}
                target="_blank"
                to={`${Paths.CreatePanel}?panelTemplate=${panelId}${
                  isPromoted ? '&isPromoted=true' : ''
                }`}
                data-testid="duplicateAndEditLink"
              >
                Duplicate and Edit
              </Button>
            )}
            {hasGenes && (
              <>
                {isManualPanel || <ButtonsDivider />}
                <Button
                  variant="primaryGhost"
                  h="40px"
                  as={BrowserLink}
                  target="_blank"
                  to={generatePath(Paths.PrintablePanel, {
                    panelType: isPromoted
                      ? PanelOwnershipType.promoted
                      : PanelOwnershipType.proprietary,
                    panelId
                  })}
                  data-testid="printableFileLink"
                >
                  Open as Printable File
                </Button>
                <ButtonsDivider />
                <DownloadGeneListButton />
              </>
            )}
            {isPromoted || (
              <Button
                variant="secondarySolid"
                h="40px"
                as={BrowserLink}
                to={generatePath(Paths.NewQuoteForPanel, { panelId })}
                data-testid="requestQuoteLink"
              >
                Request Quote
              </Button>
            )}
          </>
        ) : (
          <>
            {hasGenes && (
              <>
                <DownloadGeneListButton />
                <ButtonsDivider />
              </>
            )}
            <Button
              variant="primaryGhost"
              h="40px"
              minW="auto"
              onClick={onOpen}
              data-testid="deleteDraftPanelButton"
            >
              Delete panel
            </Button>
            <SecondaryButton
              p="8px 12px"
              icon={SecondaryButtonIcon.Edit}
              as={BrowserLink}
              to={generatePath(Paths.CreatePanel_Configuration, {
                panelId
              })}
              data-testid="editQuoteLink"
            >
              Edit
            </SecondaryButton>
          </>
        )}
      </HStack>
      <ConfirmDeletePanelModal
        isVisible={isOpen}
        panelId={panelId}
        onClose={onClose}
        onSubmit={() => {
          navigate(Paths.Panels);
        }}
      />
    </>
  );
};

import { PanelOwnershipType } from 'generated/types';
import { PersonalGenePanelModel } from 'models';
import { useMemo } from 'react';
import { getIsManualPanel } from 'utils';

export const useFilterPanels = (
  filter: string,
  panels: PersonalGenePanelModel[]
) => {
  return useMemo(() => {
    switch (filter) {
      case 'all': {
        return panels;
      }
      case PanelOwnershipType.proprietary:
      case PanelOwnershipType.promoted: {
        return panels.filter(
          (p) =>
            p.ownershipType === filter && !getIsManualPanel(p.serialNumberTag)
        );
      }
      case 'manual': {
        return panels.filter((p) => getIsManualPanel(p.serialNumberTag));
      }
      default: {
        return [];
      }
    }
  }, [filter, panels]);
};

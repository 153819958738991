import { Center, Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ModalErrorMessageProps {
  textProps?: TextProps;
}

export const ModalErrorMessage: FC<ModalErrorMessageProps> = ({
  textProps,
  children
}) => {
  if (!children) {
    return null;
  }

  return (
    <Center w="100%">
      <Text
        variant="captionHigh"
        color="state.error"
        wordBreak="break-all"
        {...textProps}
      >
        {children}
      </Text>
    </Center>
  );
};

import { Collapse, Fade } from '@chakra-ui/react';
import { FC } from 'react';
import { useFeatureFlagsStore } from 'store';
import {
  InsufficientRow,
  NonTrackableRow,
  NotFoundRow,
  DuplicatesRow,
  MaxGenesRow
} from './components';

interface IssuesProps {
  isOpen: boolean;
}

export const Issues: FC<IssuesProps> = ({ isOpen }) => {
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);

  return (
    <Fade in={isOpen}>
      <Collapse in={isOpen} animateOpacity>
        <InsufficientRow />
        <NonTrackableRow />
        <NotFoundRow />
        <DuplicatesRow />
        {gm201 && <MaxGenesRow />}
      </Collapse>
    </Fade>
  );
};

import { useMemo } from 'react';
import { getIsManualPanel } from 'utils';
import { Panel } from 'generated/types';

export const useFilterManualPanels = (
  panels: { serialNumberTag?: string | null }[]
) => {
  return useMemo(
    () =>
      panels?.filter((p) => !getIsManualPanel(p?.serialNumberTag)) as Panel[],
    [panels]
  );
};

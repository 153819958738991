import { FC } from 'react';
import { HStack, useRadioGroup } from '@chakra-ui/react';
import { GroupButton } from './components';

interface Option {
  value: string;
  label: string;
  count?: number;
}

export interface ButtonsGroupProps {
  options: Option[];
  defaultValue?: string;
  value?: string;
  onChange: (e: string) => void;
}

const firstButtonStyles = {
  borderTopLeftRadius: '32px',
  borderBottomLeftRadius: '32px'
};

const middleButtonStyles = {
  borderLeft: 'none'
};

const lastButtonStyles = {
  borderLeft: 'none',
  borderTopRightRadius: '32px',
  borderBottomRightRadius: '32px'
};

export const ButtonsGroup: FC<ButtonsGroupProps> = ({
  options,
  defaultValue,
  value,
  onChange
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue,
    value,
    onChange
  });

  const group = getRootProps();

  const isFirstButton = (index: number): boolean => {
    return index === 0;
  };

  const isLastButton = (index: number): boolean => {
    return index === options.length - 1;
  };

  const isMiddleButton = (index: number): boolean => {
    return !isFirstButton(index) && !isLastButton(index);
  };

  return (
    <HStack
      {...group}
      spacing="0"
      h="fit-content"
      w="fit-content"
      fontSize="16"
      lineHeight="24"
      display="grid"
      gridAutoColumns="minmax(0, 1fr)"
      gridAutoFlow="column"
    >
      {options.map(({ value, label, count }, index) => {
        const radio = getRadioProps({
          value,
          enterKeyHint: undefined
        });
        return (
          <GroupButton
            key={value}
            styles={{
              border: '1px solid',
              ...(isFirstButton(index) && firstButtonStyles),
              ...(isMiddleButton(index) && middleButtonStyles),
              ...(isLastButton(index) && lastButtonStyles)
            }}
            {...radio}
          >
            {label} {count !== undefined ? `(${count})` : ''}
          </GroupButton>
        );
      })}
    </HStack>
  );
};

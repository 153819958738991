import { MERFISH1_FEATURE_FLAG } from '@vizgen/vizgen-ui';
import { getFeatureFlagValue } from './getFeatureFlagValue';

export async function hasIncompatibleChemistryVersion(
  panelMerfishVersion?: string | null
): Promise<boolean> {
  const isMerfish1Allowed = await getFeatureFlagValue(
    MERFISH1_FEATURE_FLAG,
    true
  );

  return !isMerfish1Allowed && (panelMerfishVersion ?? '1') === '1';
}

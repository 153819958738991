import { FC } from 'react';
import { Table, Tbody, Text } from '@chakra-ui/react';
import { Panel } from 'generated/types';
import { SummaryRow } from '@vizgen/vizgen-ui';
import { getChemistryVersion } from '@vizgen/vizgen-ui';
import { useFeatureFlagsStore } from 'store';

interface PanelDetailsListProps {
  panel?: Panel;
  tissue: string;
  reference: string;
  auxProbes: string;
  headerWidth?: string;
  cellWidth?: string;
  isCommentTruncated?: boolean;
  matchingKit?: number;
}

export const PanelDetailsList: FC<PanelDetailsListProps> = ({
  panel,
  tissue,
  reference,
  auxProbes,
  isCommentTruncated = true,
  matchingKit,
  ...rest
}) => {
  const {
    panelName,
    genesSummary,
    isCompleted,
    panelId,
    comment,
    serialNumberTag,
    merfishVersion
  } = panel || {};
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);

  return (
    <Table mb="32px">
      <Tbody>
        <SummaryRow field="Name" value={panelName} {...rest} />

        {/* Temporarily hidden VCD-352 */}
        {/* {isPredesigned || (
          <SummaryRow
            field={
              <HStack as="span" spacing="4px">
                <Text
                  as="span"
                  variant="accentBodyShort"
                  color="content.primary"
                >
                  Proprietary
                </Text>
                <ProprietaryTooltip
                  isProprietary={isProprietary || false}
                  color={colors.state.infoDark}
                />
              </HStack>
            }
            value={isProprietary ? 'Yes' : 'No'}
          />
        )} */}
        <SummaryRow field="Reference" value={reference || '—'} {...rest} />
        <SummaryRow field="Tissue" value={tissue || '—'} {...rest} />
        {gm201 && (
          <SummaryRow
            field="Chemistry version"
            value={`MERFISH ${getChemistryVersion(merfishVersion)}`}
            {...rest}
          />
        )}
        <SummaryRow
          field="Total Genes"
          value={genesSummary?.totalNumber}
          {...rest}
        />
        <SummaryRow
          field="MERFISH"
          value={
            <>
              <Text as="span" variant="bodyShort">
                {genesSummary?.primary}
              </Text>{' '}
              {!!matchingKit && !isCompleted && (
                <Text as="span" variant="bodyShort" color="content.additional">
                  (this panel requires {matchingKit} gene imaging kit)
                </Text>
              )}
            </>
          }
          {...rest}
        />
        <SummaryRow
          field="Sequential"
          value={genesSummary?.sequential}
          {...rest}
        />
        <SummaryRow field="Aux Probes" value={auxProbes || '—'} {...rest} />
        {matchingKit !== 0 && isCompleted && (
          <SummaryRow field="Kit Size" value={matchingKit || '—'} {...rest} />
        )}
        <SummaryRow
          field="Total MERFISH Abundance"
          value={genesSummary?.totalAbundance}
          {...rest}
        />
        <SummaryRow
          field="Description"
          value={comment || '—'}
          isTruncated={isCommentTruncated}
          {...rest}
        />
        <SummaryRow field="Panel ID" value={panelId || '—'} {...rest} />
        <SummaryRow
          field="Serial Number"
          valueProps={serialNumberTag ? {} : { color: 'content.additional' }}
          value={serialNumberTag || 'Not assigned until panel is finalized'}
          {...rest}
        />
      </Tbody>
    </Table>
  );
};

import { HStack, Text } from '@chakra-ui/react';
import { getIsManualPanel } from 'utils';
import { Panel, PanelOwnershipType } from 'generated/types';
import { FC } from 'react';
import { formatDateTime } from '@vizgen/vizgen-ui';

interface PanelStatusProps {
  panel: Panel;
}

export const PanelStatus: FC<PanelStatusProps> = ({ panel }) => {
  const isPromoted = panel.ownershipType === PanelOwnershipType.promoted;
  const isManualPanel = getIsManualPanel(panel.serialNumberTag);

  return (
    <HStack spacing="32px" mt="16px">
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Last updated:
        </Text>{' '}
        {formatDateTime(panel.updatedAt)}
      </Text>
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Status:
        </Text>{' '}
        {isPromoted
          ? 'MERSCOPE Panel'
          : panel.isCompleted
          ? 'Completed'
          : 'Draft'}
      </Text>
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Source:
        </Text>{' '}
        {isManualPanel ? 'Manual' : 'Portal'} Design
      </Text>
    </HStack>
  );
};

import { Stack, Text } from '@chakra-ui/react';
import { ExtraModel } from 'api';
import { Extra } from 'components';
import { IMAGING_KIT } from 'consts';
import { FC } from 'react';
import { ExtraControl } from './components';

interface ExtraItemProps {
  title: string;
  type: string;
  extras: ExtraModel[];
  isCompact?: boolean;
  isDropdownView?: boolean;
  caption?: string;
}

export const ExtraItem: FC<ExtraItemProps> = ({
  extras,
  title,
  type,
  isCompact,
  isDropdownView,
  caption
}) => {
  const filteredExtras = extras
    .filter((extra) => extra.extraType === type)
    .sort((a, b) =>
      a.extraType === IMAGING_KIT ? 0 : a.extraName > b.extraName ? 1 : -1
    );

  if (!filteredExtras.length) {
    return null;
  }

  if (isDropdownView) {
    return (
      <Stack spacing="8px">
        <Text>{title}</Text>
        <ExtraControl extras={filteredExtras} />
      </Stack>
    );
  }

  return (
    <Stack spacing={isCompact ? '8px' : '12px'}>
      <Text variant={isCompact ? '' : 'accentBodyShort'}>{title}</Text>
      <Text color="content.additional" variant="captionHigh">
        {caption}
      </Text>
      <Stack spacing="16px">
        {filteredExtras.map((extra) => (
          <Extra key={extra.extraId} extra={extra} />
        ))}
      </Stack>
    </Stack>
  );
};

import {
  ToastNotification,
  ToastNotificationType
} from '../components/ToastNotification';
import { useNotificationToast } from './useNotificationToast';

export const useSuccessNotification = (duration: number | null = 5000) => {
  const toast = useNotificationToast(duration);

  const show = (message: string) => {
    toast({
      render: ({ onClose }) => (
        <ToastNotification
          type={ToastNotificationType.Success}
          text={message}
          onClose={duration ? undefined : onClose}
        />
      )
    });
  };

  return show;
};

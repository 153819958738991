import { PanelTranscriptType } from 'generated/types';
import {
  getMaxMerfishGenesAmount,
  transcriptsByTypeSelector,
  useCreatePanelStore
} from 'store';

export function useGenesAmountExceeded() {
  const primaryTranscripts = useCreatePanelStore(
    transcriptsByTypeSelector(PanelTranscriptType.primary)
  ).length;
  const merfishVersion = useCreatePanelStore(
    (state) => state.panel?.merfishVersion
  );
  const maxPrimaryTranscripts = getMaxMerfishGenesAmount(merfishVersion);

  return {
    isGenesAmountExceeded: primaryTranscripts > maxPrimaryTranscripts,
    maxPrimaryTranscripts
  };
}

export const createPanelMutation = /* GraphQL */ `
  mutation CreatePanel(
    $input: CreatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    createPanel(input: $input, condition: $condition) {
      panelId
      merfishVersion
      tissueId
      transcriptomeId
      userId
      panelName
      merfishVersion
      isCompleted
      statusMessage
      comment
      genesSummary {
        totalNumber
        primary
        sequential
        totalAbundance
      }
      hasCodebook
      createdAt
      updatedAt
      owner
      serialNumberTag
      isProprietary
      ownershipType
      genes {
        nextToken
      }
    }
  }
`;

export const getPanelQuery = /* GraphQL */ `
  query GetPanel($panelId: ID!) {
    getPanel(panelId: $panelId) {
      panelId
      merfishVersion
      tissueId
      transcriptomeId
      userId
      panelName
      merfishVersion
      isCompleted
      comment
      genesSummary {
        totalNumber
        primary
        sequential
        totalAbundance
      }
      hasCodebook
      updatedAt
      owner
      serialNumberTag
      isProprietary
      ownershipType
    }
  }
`;

export const listTissues = /* GraphQL */ `
  query ListTissues {
    listTissues {
      tissueId
      transcriptomeId
      name
      enabled
    }
  }
`;

export const listTranscriptomes = /* GraphQL */ `
  query ListTranscriptomes($transcriptomeIds: [String!]) {
    listTranscriptomes(transcriptomeIds: $transcriptomeIds) {
      transcriptomeId
      name
      scientificName
    }
  }
`;

export const updatePanelMutation = /* GraphQL */ `
  mutation UpdatePanel(
    $input: UpdatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    updatePanel(input: $input, condition: $condition) {
      panelId
      merfishVersion
      tissueId
      transcriptomeId
      userId
      panelName
      merfishVersion
      isCompleted
      statusMessage
      comment
      genesSummary {
        totalNumber
        primary
        sequential
        totalAbundance
      }
      hasCodebook
      createdAt
      updatedAt
      owner
      serialNumberTag
      isProprietary
      ownershipType
      genes {
        nextToken
      }
      ownershipType
    }
  }
`;

export const createPanelTranscriptsFromGenesMutation = /* GraphQL */ `
  mutation CreatePanelTranscriptsFromGenes(
    $input: CreatePanelTranscriptsFromGenesInput!
  ) {
    createPanelTranscriptsFromGenes(input: $input) {
      run {
        panelId
        transcriptId
        parentPanelId
        targetRegionQty
        abundance
        refAbundance
        geneName
        transcriptomeId
        tissueId
        userSpecifiedName
        isLocked
        isIncluded
        status
        source
        statusMessage
        comment
        type
        duplicated
        createdAt
        updatedAt
        owner
        synonyms {
          geneSymbol
          score
          status
          targetRegionQty
        }
        geneId
      }
    }
  }
`;

export const listPromotedPanelTranscriptsQuery = /* GraphQL */ `
  query ListPromotedPanelTranscripts(
    $promotedPanelId: String!
    $limit: Int
    $nextToken: String
  ) {
    listPromotedPanelTranscripts(
      promotedPanelId: $promotedPanelId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        panelId
        transcriptId
        parentPanelId
        targetRegionQty
        abundance
        refAbundance
        geneName
        transcriptomeId
        tissueId
        userSpecifiedName
        isLocked
        status
        isIncluded
        statusMessage
        comment
        type
        source
        auxProbes
        duplicated
        createdAt
        updatedAt
        geneId
      }
      nextToken
    }
  }
`;

export const listPanelTranscriptsQuery = /* GraphQL */ `
  query ListPanelTranscripts(
    $panelId: ID
    $transcriptId: ModelStringKeyConditionInput
    $filter: ModelPanelTranscriptFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPanelTranscripts(
      panelId: $panelId
      transcriptId: $transcriptId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        panelId
        transcriptId
        parentPanelId
        targetRegionQty
        abundance
        refAbundance
        geneName
        transcriptomeId
        tissueId
        userSpecifiedName
        isLocked
        status
        source
        statusMessage
        comment
        type
        createdAt
        updatedAt
        owner
        isIncluded
        auxProbes
        geneId
        synonyms {
          geneSymbol
          score
          status
          targetRegionQty
        }
      }
      nextToken
    }
  }
`;
export const listQuotesQuery = /* GraphQL */ `
  query ListQuotes(
    $quoteId: ID
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuotes(
      quoteId: $quoteId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        quoteId
        totalGenes
        totalExtras
        Status
        statusChangedAt
        trackingNumber
        deliveryAddress
        billingAddress
        comment
        totalPrice
        userId
        createdAt
        updatedAt
        owner
        quotepositions {
          items {
            panel {
              panelId
              merfishVersion
              panelName
              ownershipType
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const updatePanelTranscriptMutation = /* GraphQL */ `
  mutation UpdatePanelTranscript(
    $input: UpdatePanelTranscriptInput!
    $condition: ModelPanelTranscriptConditionInput
  ) {
    updatePanelTranscript(input: $input, condition: $condition) {
      panelId
      transcriptId
      parentPanelId
      targetRegionQty
      abundance
      geneName
      transcriptomeId
      tissueId
      userSpecifiedName
      isIncluded
      status
      statusMessage
      source
      comment
      type
      createdAt
      updatedAt
      owner
      auxProbes
    }
  }
`;

export const deletePanelTranscriptMutation = /* GraphQL */ `
  mutation DeletePanelTranscript(
    $input: DeletePanelTranscriptInput!
    $condition: ModelPanelTranscriptConditionInput
  ) {
    deletePanelTranscript(input: $input, condition: $condition) {
      panelId
      transcriptId
    }
  }
`;

export const listExtrasQuery = /* GraphQL */ `
  query ListExtras($extraType: String) {
    listExtras(extraType: $extraType) {
      extraId
      extraType
      extraName
      description
      isAntibodyKit
      auxProbesUsed
    }
  }
`;

export const getQuoteQuery = /* GraphQL */ `
  query GetQuote($quoteId: ID!) {
    getQuote(quoteId: $quoteId) {
      quoteId
      totalGenes
      totalExtras
      Status
      statusChangedAt
      trackingNumber
      deliveryAddress
      billingAddress
      comment
      totalPrice
      userId
      createdAt
      updatedAt
      owner
      quotepositions {
        items {
          quotePositionId
          panelId
          extraId
          quantity
          cartridgeId
          createdAt
          updatedAt
          extra {
            extraId
            extraType
            extraName
            description
            isAntibodyKit
            auxProbesUsed
          }
          panel {
            userId
            updatedAt
            transcriptomeId
            tissueId
            statusMessage
            panelName
            panelId
            merfishVersion
            owner
            isCompleted
            hasCodebook
            createdAt
            comment
            serialNumberTag
            isProprietary
            ownershipType
            genesSummary {
              totalNumber
              primary
            }
            ownershipType
            genes {
              items {
                auxProbes
              }
              nextToken
            }
          }
        }
      }
      feedback {
        nextToken
      }
    }
  }
`;

export const createQuoteMutation = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      quoteId
      totalGenes
      totalExtras
      Status
      statusChangedAt
      trackingNumber
      deliveryAddress
      billingAddress
      comment
      totalPrice
      userId
      createdAt
      updatedAt
      owner
      quotepositions {
        nextToken
      }
      feedback {
        nextToken
      }
    }
  }
`;

export const createQuotePositionMutation = /* GraphQL */ `
  mutation CreateQuotePosition(
    $input: CreateQuotePositionInput!
    $condition: ModelQuotePositionConditionInput
  ) {
    createQuotePosition(input: $input, condition: $condition) {
      quoteId
      quotePositionId
      panelId
      extraId
      quantity
      cartridgeId
      listPricePerItem
      pricePerPosition
      createdAt
      updatedAt
      panel {
        panelId
        merfishVersion
        tissueId
        transcriptomeId
        userId
        panelName
        merfishVersion
        isCompleted
        statusMessage
        comment
        hasCodebook
        createdAt
        updatedAt
        owner
        ownershipType
      }
      quote {
        quoteId
        totalGenes
        totalExtras
        Status
        statusChangedAt
        trackingNumber
        deliveryAddress
        billingAddress
        comment
        totalPrice
        userId
        createdAt
        updatedAt
        owner
      }
      extra {
        extraId
        extraType
        extraName
        description
      }
      owner
      cartridge {
        cartridgeId
        barcode
        description
        expirationDate
        reagentCartridgeMapNumber
        cartridgeLotNumber
        isUsed
        isAvailable
        isCreated
        createdAt
        updatedAt
        owner
      }
    }
  }
`;

export const finalizePanelMutation = /* GraphQL */ `
  mutation FinalizePanel($panelId: ID!) {
    finalizePanel(panelId: $panelId)
  }
`;

export const createAddressMutation = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      addressId
      address
      addressType
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;

export const listAddressesQuery = /* GraphQL */ `
  query ListAddresss(
    $addressId: ID
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAddresses(
      addressId: $addressId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        addressId
        address
        addressType
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const deleteAddressMutation = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      addressId
      address
      addressType
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;

export const listFinalizedPanelsByTissue = /* GraphQL */ `
  query ListFinalizedPanelsByTissue(
    $tissueId: String!
    $limit: Int
    $nextToken: String
  ) {
    listFinalizedPanelsByTissue(
      tissueId: $tissueId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        panelId
        merfishVersion
        tissueId
        transcriptomeId
        panelName
        genesSummary {
          totalNumber
          primary
          sequential
          totalAbundance
        }
        comment
        ownershipType
      }
      nextToken
    }
  }
`;

export const addPromotedPanelToPanelMutation = /* GraphQL */ `
  mutation AddPromotedPanelToPanel($panelId: ID!, $promotedPanelId: ID!) {
    addPromotedPanelToPanel(
      panelId: $panelId
      promotedPanelId: $promotedPanelId
    ) {
      input {
        panelId
        transcriptId
        tissueId
        transcriptomeId
        geneName
        userSpecifiedName
        status
        targetRegionQty
        abundance
        comment
        type
        source
        owner
        createdAt
        updatedAt
      }
      run {
        comment
        status
        abundance
        statusMessage
        geneName
        type
        targetRegionQty
        transcriptId
        userSpecifiedName
        isIncluded
        source
      }
    }
  }
`;

export const addFinalizedPanelToPanelMutation = /* GraphQL */ `
  mutation AddFinalizedPanelToPanel($panelId: ID!, $finalizedPanelId: ID!) {
    addFinalizedPanelToPanel(
      panelId: $panelId
      finalizedPanelId: $finalizedPanelId
    ) {
      input {
        panelId
        transcriptId
        tissueId
        transcriptomeId
        geneName
        userSpecifiedName
        status
        targetRegionQty
        abundance
        comment
        type
        source
        owner
        createdAt
        updatedAt
      }
      run {
        comment
        status
        abundance
        statusMessage
        geneName
        type
        targetRegionQty
        transcriptId
        userSpecifiedName
        isIncluded
        source
        auxProbes
      }
    }
  }
`;

export const createExogenousSequence = /* GraphQL */ `
  mutation CreateExogenousSequence(
    $input: CreateExogenousSequenceInput!
    $condition: ModelExogenousSequenceConditionInput
  ) {
    createExogenousSequence(input: $input, condition: $condition) {
      id
      name
      sequence
      fpkm
      owner
      createdAt
      updatedAt
    }
  }
`;

export const listExogenousSequences = /* GraphQL */ `
  query ListExogenousSequences(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelExogenousSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExogenousSequences(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sequence
        fpkm
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPanelsByOwnerQuery = /* GraphQL */ `
  query GetPanelsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanelsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        updatedAt
        transcriptomeId
        tissueId
        panelName
        panelId
        merfishVersion
        owner
        isCompleted
        hasCodebook
        comment
        serialNumberTag
        isProprietary
        ownershipType
        genesSummary {
          totalNumber
          primary
        }
        ownershipType
      }
      nextToken
    }
  }
`;

export const getPanelsWithGenesByOwnerQuery = /* GraphQL */ `
  query GetPanelsWithGenesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPanelFilterInput
    $genesFilter: ModelPanelTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanelsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        updatedAt
        transcriptomeId
        tissueId
        panelName
        panelId
        merfishVersion
        owner
        isCompleted
        hasCodebook
        comment
        serialNumberTag
        isProprietary
        ownershipType
        genesSummary {
          totalNumber
          primary
        }
        ownershipType
        genes(filter: $genesFilter) {
          items {
            auxProbes
          }
        }
      }
      nextToken
    }
  }
`;

export const getPromotedPanelsWithGenesQuery = /* GraphQL */ `
  query ListPromotedPanelsWithGenes(
    $limit: Int
    $nextToken: String
    $tissueId: String
    $genesFilter: ModelPanelTranscriptFilterInput
  ) {
    listPromotedPanels(
      limit: $limit
      nextToken: $nextToken
      tissueId: $tissueId
    ) {
      items {
        userId
        updatedAt
        transcriptomeId
        tissueId
        panelName
        panelId
        merfishVersion
        owner
        isCompleted
        hasCodebook
        comment
        serialNumberTag
        isProprietary
        genesSummary {
          totalNumber
          primary
        }
        ownershipType
        genes(filter: $genesFilter) {
          items {
            auxProbes
          }
        }
      }
      nextToken
    }
  }
`;

export const listPanelTranscriptsBySource = /* GraphQL */ `
  query ListPanelTranscriptsBySource(
    $panelId: ID!
    $source: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPanelTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanelTranscriptsBySource(
      panelId: $panelId
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        panelId
        transcriptId
        parentPanelId
        targetRegionQty
        abundance
        refAbundance
        geneName
        transcriptomeId
        tissueId
        userSpecifiedName
        isLocked
        status
        isIncluded
        statusMessage
        comment
        type
        source
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const addExogenousSequenceToPanel = /* GraphQL */ `
  mutation AddExogenousSequenceToPanel(
    $panelId: ID!
    $exogenousSequenceId: ID!
  ) {
    addExogenousSequenceToPanel(
      panelId: $panelId
      exogenousSequenceId: $exogenousSequenceId
    ) {
      panelId
      transcriptId
      parentPanelId
      targetRegionQty
      abundance
      geneName
      transcriptomeId
      tissueId
      userSpecifiedName
      status
      isIncluded
      statusMessage
      comment
      type
      source
      createdAt
      updatedAt
      geneId
      synonyms {
        geneSymbol
        score
        status
        targetRegionQty
      }
      transcript {
        transcriptId
        ensembleTranscriptId
        ensembleTranscriptRev
        transcriptomeId
        geneId
        geneName
        geneBioType
        geneIdRev
        source
        sequence
        description
      }
      transcriptTargetRegions {
        transcriptId
        tissueId
        transcriptomeId
        fpkm
        targetRegionQty
        minimumGeneSpecificity
        minimumIsoformSpecificity
        minimumGC
        maximumGC
        minimumTm
        maximumTm
        overlap
        createdAt
        updatedAt
      }
      panel {
        panelId
        merfishVersion
        tissueId
        transcriptomeId
        userId
        panelName
        merfishVersion
        isCompleted
        statusMessage
        comment
        hasCodebook
        owner
        createdAt
        updatedAt
        serialNumberTag
        isProprietary
        ownershipType
      }
      owner
    }
  }
`;

export const sendEmailToUser = /* GraphQL */ `
  mutation SendEmailToUser($subject: String!, $bodyHtml: String!) {
    sendEmailToUser(subject: $subject, bodyHtml: $bodyHtml)
  }
`;

export const listFileResources = /* GraphQL */ `
  query ListFileResources(
    $resourceId: String
    $filter: ModelFileResourceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileResources(
      resourceId: $resourceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        resourceId
        description
        product
        category
        version
        resourceDate
        isFeatured
        isNew
        path
        isHidden
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUserProps = /* GraphQL */ `
  query GetUserProps($userId: ID) {
    getUserProps(userId: $userId) {
      userId
      isEnabled
      email
      emailVerified
      accountStatus
      name
      familyName
      countryId
      countryName
      regionId
      regionName
      userType
      regionalDistributor {
        distributorId
        name
        email
      }
      assignedDistributorId
      assignedDistributor {
        distributorId
        name
        email
      }
      organization
      laboratory
    }
  }
`;

export const updateUserProps = /* GraphQL */ `
  mutation UpdateUserProps($userProps: UserPropsInput!) {
    updateUserProps(userProps: $userProps) {
      userId
      isEnabled
      email
      emailVerified
      accountStatus
      name
      familyName
      countryId
      countryName
      regionId
      regionName
      userType
      organization
      laboratory
    }
  }
`;

export const listCountries = /* GraphQL */ `
  query ListCountries {
    listCountries {
      countryId
      name
      totalRegions
    }
  }
`;

export const listRegions = /* GraphQL */ `
  query ListRegions($countryId: String!) {
    listRegions(countryId: $countryId) {
      countryId
      regionId
      country {
        countryId
        name
        totalRegions
      }
      name
    }
  }
`;

export const approveAccount = /* GraphQL */ `
  mutation ApproveAccount($approvalRequestId: String!) {
    approveAccount(approvalRequestId: $approvalRequestId)
  }
`;

export const deleteGeneBlacklistItem = /* GraphQL */ `
  mutation DeleteGeneBlacklistItem($id: String!) {
    deleteGeneBlacklistItem(id: $id)
  }
`;

export const listInstruments = /* GraphQL */ `
  query ListInstruments($nextToken: String) {
    listInstruments(nextToken: $nextToken) {
      items {
        lastStatusUpdated
        status
        uniqueId
        serialNumber
        whoAmI
        admins {
          name
          familyName
          userId
          email
        }
        viewers {
          userId
          name
          familyName
          email
        }
      }
      nextToken
    }
  }
`;

export const updateUserInstrumentNotifications = /* GraphQL */ `
  mutation UpdateUserInstrumentNotifications(
    $instrumentUniqueId: String!
    $events: [String!]!
  ) {
    updateUserInstrumentNotifications(
      instrumentUniqueId: $instrumentUniqueId
      events: $events
    )
  }
`;

export const listUserInstrumentNotifications = /* GraphQL */ `
  query ListUserInstrumentNotifications($instrumentUniqueId: String!) {
    listUserInstrumentNotifications(instrumentUniqueId: $instrumentUniqueId)
  }
`;

export const removeInstrumentUser = /* GraphQL */ `
  mutation RemoveInstrumentUser($input: InstrumentUserInput!) {
    removeInstrumentUser(input: $input) {
      lastStatusUpdated
      status
      uniqueId
      serialNumber
      whoAmI
      admins {
        name
        familyName
        userId
        email
      }
      viewers {
        userId
        name
        familyName
        email
      }
    }
  }
`;

export const addInstrumentUser = /* GraphQL */ `
  mutation AddInstrumentUser($input: InstrumentUserInput!) {
    addInstrumentUser(input: $input) {
      lastStatusUpdated
      status
      uniqueId
      serialNumber
      whoAmI
      admins {
        name
        familyName
        userId
        email
      }
      viewers {
        userId
        name
        familyName
        email
      }
    }
  }
`;

export const getCurrentGeneSetCollection = /* GraphQL */ `
  query GetCurrentGeneSetCollection($transcriptomeId: String) {
    getCurrentGeneSetCollection(transcriptomeId: $transcriptomeId) {
      geneSetS3Key
      geneSetVersion
      geneSetCommitId
    }
  }
`;

export const getPanelUserView = /* GraphQL */ `
  query GetPanelUserView($userId: ID!, $panelId: ID!) {
    getPanelUserView(userId: $userId, panelId: $panelId) {
      geneSortMethod
      geneSetSortMethod
      geneSets
      geneSetS3Key
      geneSetVersion
      geneSetCommitId
    }
  }
`;

export const createPanelUserView = /* GraphQL */ `
  mutation CreatePanelUserView(
    $input: CreatePanelUserViewInput!
    $condition: ModelPanelUserViewConditionInput
  ) {
    createPanelUserView(input: $input, condition: $condition) {
      geneSortMethod
      geneSetSortMethod
      geneSets
      geneSetS3Key
      geneSetVersion
      geneSetCommitId
    }
  }
`;

export const updatePanelUserView = /* GraphQL */ `
  mutation UpdatePanelUserView(
    $input: UpdatePanelUserViewInput!
    $condition: ModelPanelUserViewConditionInput
  ) {
    updatePanelUserView(input: $input, condition: $condition) {
      geneSortMethod
      geneSetSortMethod
      geneSets
      geneSetS3Key
      geneSetVersion
      geneSetCommitId
    }
  }
`;

export const deletePanelTranscriptsBatch = /* GraphQL */ `
  mutation DeletePanelTranscriptsBatch(
    $input: [DeletePanelTranscriptsBatchInput!]!
  ) {
    DeletePanelTranscriptsBatch(input: $input) {
      transcriptId
    }
  }
`;

export const listPromotedPanels = /* GraphQL */ `
  query ListPromotedPanels($limit: Int, $nextToken: String, $tissueId: String) {
    listPromotedPanels(
      limit: $limit
      nextToken: $nextToken
      tissueId: $tissueId
    ) {
      items {
        panelName
        panelId
        merfishVersion
        transcriptomeId
        tissueId
        comment
        updatedAt
        serialNumberTag
        isCompleted
        genesSummary {
          totalNumber
          primary
          sequential
          totalAbundance
        }
        ownershipType
        application
        platform
        isCompleted
      }
      nextToken
    }
  }
`;

export const getPromotedPanelQuery = /* GraphQL */ `
  query GetPromotedPanel($promotedPanelId: String!) {
    getPromotedPanel(promotedPanelId: $promotedPanelId) {
      panelId
      merfishVersion
      tissueId
      transcriptomeId
      userId
      panelName
      isCompleted
      comment
      genesSummary {
        totalNumber
        primary
        sequential
        totalAbundance
      }
      hasCodebook
      updatedAt
      serialNumberTag
      isProprietary
      ownershipType
      application
      platform
    }
  }
`;

export const listFeatureToggles = /* GraphQL */ `
  query ListFeatureToggles(
    $name: String
    $filter: ModelFeatureToggleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeatureToggles(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        state
      }
      nextToken
    }
  }
`;

export const getPresignedURLForCodebookQuery = /* GraphQL */ `
  query GetPresignedURLForCodebook($panelId: ID!) {
    getPresignedURLForCodebook(panelId: $panelId) {
      url
    }
  }
`;

export enum Category {
  Software = 'Software',
  UserGuides = 'UserGuides',
  ApplicationAndTechnicalNotes = 'ApplicationAndTechnicalNotes',
  SafetyDataSheets = 'SafetyDataSheets',
  ProductNotifications = 'ProductNotifications'
}

export enum Product {
  Vizualizer = 'Vizualizer',
  Platform = 'Platform',
  PreparationKit = 'PreparationKit',
  SampleVerificationKit = 'SampleVerificationKit',
  GenePanelPortal = 'GenePanelPortal',
  CellBoundaryKit = 'CellBoundaryKit',
  ImagingKit = 'ImagingKit',
  GenePanel = 'GenePanel',
  TrainingKit = 'TrainingKit',
  VerificationKit = 'VerificationKit',
  UltraPlatform = 'UltraPlatform',
  FFPESamplePrepKit = 'FFPESamplePrepKit',
  ProteinVerificationKit = 'ProteinVerificationKit',
  Slides = 'Slides',
  Panels = 'Panels',
  ProteinStainKits = 'ProteinStainKits',
  Photobleacher = 'Photobleacher',
  WashCartridge = 'WashCartridge',
  SamplePlacementGuide = 'SamplePlacementGuide'
}

export const CATEGORY_LABELS: Record<Category, string> = {
  Software: 'Software',
  UserGuides: 'User Guides',
  ApplicationAndTechnicalNotes: 'Application & Technical Notes',
  SafetyDataSheets: 'Safety Data Sheets',
  ProductNotifications: 'Product Notifications'
};

export const PRODUCT_LABELS: Record<Product, string> = {
  Vizualizer: 'MERSCOPE Vizualizer',
  Platform: 'MERSCOPE Platform',
  PreparationKit: 'MERSCOPE Sample Preparation Kit',
  SampleVerificationKit: 'MERSCOPE Sample Verification Kit',
  GenePanelPortal: 'Gene Panel Portal',
  CellBoundaryKit: 'MERSCOPE Cell Boundary Kit',
  ImagingKit: 'MERSCOPE Imaging Kit',
  GenePanel: 'MERSCOPE Gene Panel',
  TrainingKit: 'MERSCOPE Training Kit',
  VerificationKit: 'MERSCOPE Verification Kit',
  UltraPlatform: 'MERSCOPE Ultra Platform',
  FFPESamplePrepKit: 'MERSCOPE FFPE Sample Prep Kit',
  ProteinVerificationKit: 'MERSCOPE Protein Verification Kit',
  Slides: 'MERSCOPE Slides',
  Panels: 'MERSCOPE Panels',
  ProteinStainKits: 'MERSCOPE Protein Stain Kits',
  Photobleacher: 'MERSCOPE Photobleacher',
  WashCartridge: 'MERSCOPE Wash Cartridge',
  SamplePlacementGuide: 'Sample Placement Guide'
};

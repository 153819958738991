import { FC, useEffect } from 'react';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { FeatureFlags, useFeatureFlagsStore, useUserStore } from 'store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import version from './version.json';
import { FontsConfig, VizgenUIProvider } from '@vizgen/vizgen-ui';
import { AuthWrapper } from 'components';
import { RoutesList } from 'routes/RoutesList';
import { BrowserRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

console.log('Gene Panel', version);
(window as any).version = version;

Amplify.configure(awsconfig);

const queryClient = new QueryClient();

const App: FC = () => {
  const checkAuth = useUserStore((state) => state.checkAuth);
  const setFeatureFlags = useFeatureFlagsStore(
    (state) => state.setFeatureFlags
  );
  const flags = useFlags();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    setFeatureFlags({ ...(flags as FeatureFlags) });
  }, [flags, setFeatureFlags]);

  return (
    <QueryClientProvider client={queryClient}>
      <VizgenUIProvider>
        <FontsConfig />
        <BrowserRouter>
          <AuthWrapper>
            <RoutesList />
          </AuthWrapper>
        </BrowserRouter>
      </VizgenUIProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;

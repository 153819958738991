export interface GraphQLResult {
  errors?: [{ message: string }];
}

export const formatErrorMessages = (e: any, isPureMessage = false): string => {
  const errors = (
    (e as GraphQLResult)?.errors?.map(
      ({ message }) => `"${message?.trim()}"`
    ) || [e?.message?.trim() || 'Unknown error']
  ).join('; ');
  return isPureMessage ? errors : `Request failed: ${errors}`;
};

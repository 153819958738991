import { FC } from 'react';
import { HStack, VStack, Text, StackDivider } from '@chakra-ui/react';
import { OkIcon, WarningIcon } from './assets';
import {
  useCreatePanelStore,
  totalPrimaryAbundanceSelector,
  merfishThresholdGenesAmountSelector,
  isTotalAbundanceInvalidSelector
} from 'store';
import { MAX_MERFISH_GENE_ABUNDANCE, MAX_TOTAL_ABUNDANCE } from 'consts';
import { ErrorFilledSmallIcon } from 'assets';
import { shallow } from 'zustand/shallow';

export const Label: FC = () => {
  const [totalAbundance, merfishThresholdGenesAmount, isTotalAbundanceInvalid] =
    useCreatePanelStore(
      (state) => [
        totalPrimaryAbundanceSelector(state),
        merfishThresholdGenesAmountSelector(state),
        isTotalAbundanceInvalidSelector(state)
      ],
      shallow
    );
  const merfishThresholdGenesAmountText =
    merfishThresholdGenesAmount > 1 ? 'genes exceed' : 'gene exceeds';

  return (
    <>
      <VStack
        spacing="14px"
        w="100%"
        alignItems="flex-start"
        divider={<StackDivider borderColor="border.divider" />}
      >
        <VStack spacing="6px" w="100%" alignItems="flex-start">
          <Text variant="bodyShort" color="content.tertiary">
            Total abundance:
          </Text>
          <HStack spacing="4px">
            {isTotalAbundanceInvalid ? <WarningIcon /> : <OkIcon />}
            <Text color="content.tertiary" variant="accentBodyShort">
              <Text
                as="span"
                color={
                  isTotalAbundanceInvalid ? 'state.error' : 'state.success'
                }
                variant="accentBodyShort"
              >
                {totalAbundance}
              </Text>
              <Text as="span" variant="bodyShort" color="content.tertiary">
                /
              </Text>
              {MAX_TOTAL_ABUNDANCE}
            </Text>
          </HStack>
          {isTotalAbundanceInvalid && (
            <Text variant="captionHigh" color="content.tertiary">
              Delete some genes or move them to Sequential Panel
            </Text>
          )}
        </VStack>
        {merfishThresholdGenesAmount && (
          <VStack spacing="6px" w="100%" alignItems="flex-start">
            <Text variant="bodyShort" color="content.tertiary">
              Gene abundance:
            </Text>
            <HStack spacing="4px">
              <ErrorFilledSmallIcon />
              <Text color="content.tertiary" variant="accentBodyShort">
                {merfishThresholdGenesAmount} {merfishThresholdGenesAmountText}{' '}
                threshold
              </Text>
            </HStack>
            <Text variant="captionHigh" color="content.tertiary">
              {merfishThresholdGenesAmount} {merfishThresholdGenesAmountText}{' '}
              single gene abundance threshold ({MAX_MERFISH_GENE_ABUNDANCE} FPKM
              for MERFISH Panel)
              <br />
              <br />
              Use column sorting to bring this genes to top and delete them or
              move to Sequential panel
            </Text>
          </VStack>
        )}
      </VStack>
    </>
  );
};

import { FC } from 'react';
import {
  Box,
  HStack,
  StackDivider,
  VStack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button
} from '@chakra-ui/react';
import { ThreeDotsIcon } from 'assets';
import { PanelModel } from 'api/types';
import { useFeatureFlagsStore, useGlobalListsStore } from 'store';
import {
  TooltipText,
  formatDateTime,
  getChemistryVersion
} from '@vizgen/vizgen-ui';

interface PanelProps extends PanelModel {
  hasMenu?: boolean;
}

export const Panel: FC<PanelProps> = ({
  panelName,
  updatedAt,
  transcriptomeId,
  tissueId,
  genesSummary,
  comment,
  merfishVersion,
  hasMenu = false
}) => {
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const tissues = useGlobalListsStore((state) => state.tissues);
  const tissueName =
    tissues.find((t) => t.tissueId === tissueId)?.name || tissueId || '';
  const referenceType =
    references.find((r) => r.transcriptomeId === transcriptomeId)?.name ||
    transcriptomeId ||
    '';

  return (
    <Box
      w="100%"
      p="12px"
      border="1px solid"
      borderRadius="8px"
      borderColor="border.normal"
    >
      <HStack
        spacing="20px"
        alignItems="flex-start"
        divider={<StackDivider borderColor="border.divider" />}
      >
        <HStack spacing="8px">
          <VStack spacing="8px" alignItems="start" w="235px">
            <Text
              variant="bodyShort"
              color="content.primary"
              fontWeight="heavy"
            >
              {panelName}
            </Text>
            {gm201 && (
              <HStack spacing="4px">
                <Text variant="bodyShort" color="content.additional">
                  Chemistry version:
                </Text>
                <Text
                  variant="bodyShort"
                  color="content.primary"
                  whiteSpace="nowrap"
                >
                  MERFISH {getChemistryVersion(merfishVersion)}
                </Text>
              </HStack>
            )}
            <HStack spacing="4px">
              <Text variant="bodyShort" color="content.additional">
                Updated:
              </Text>
              <Text
                variant="bodyShort"
                color="content.primary"
                whiteSpace="nowrap"
              >
                {formatDateTime(updatedAt)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <HStack spacing="8px" w="100%">
          <Table
            size="sm"
            variant="unstyled"
            maxW={hasMenu ? '465px' : '485px'}
            mr="auto"
          >
            <Thead>
              <Tr color="content.additional">
                <Th p="0 24px 8px 0" maxW="150px">
                  Reference
                </Th>
                <Th p="0 24px 8px 0" maxW="182px">
                  Tissue
                </Th>
                <Th p="0 24px 8px 0" isNumeric>
                  Genes
                </Th>
                <Th p="0 0 8px 0">Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td p="0 24px 0 0" maxW="150px">
                  <TooltipText>{referenceType}</TooltipText>
                </Td>
                <Td p="0 24px 0 0" maxW="182px" title={tissueName}>
                  <TooltipText>{tissueName}</TooltipText>
                </Td>
                <Td p="0 24px 0 0" isNumeric>
                  {genesSummary.totalNumber}
                </Td>
                <Td p="0" maxW="159px" w="159px" title={comment || ''}>
                  <TooltipText> {comment ? comment : '-'}</TooltipText>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          {hasMenu && (
            <Button
              variant="ghost"
              minW="24px"
              minH="24px"
              w="24px"
              h="24px"
              p="0"
            >
              <ThreeDotsIcon />
            </Button>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

import { HStack, Text } from '@chakra-ui/react';
import { useGenesAmountExceeded } from 'hooks';
import { FC } from 'react';
import { UpIcon } from './assets';

export const MaxGenesIssue: FC = () => {
  const { isGenesAmountExceeded, maxPrimaryTranscripts } =
    useGenesAmountExceeded();

  if (!isGenesAmountExceeded) {
    return null;
  }

  return (
    <HStack spacing="8px">
      <UpIcon />
      <Text color="content.additional">
        too many genes added (max {maxPrimaryTranscripts} allowed)
      </Text>
    </HStack>
  );
};

import { FeatureToggle } from 'generated/types';
import { listFeatureTogglesQuery } from 'api';

export async function getFeatureFlagValue<T>(
  name: string,
  defaultValue: T
): Promise<T> {
  const result = await listFeatureTogglesQuery({ name });

  return parseFeatureFlagValue(result, defaultValue);
}

export function parseFeatureFlagValue<T>(
  result: FeatureToggle[],
  defaultValue: T
): T {
  const value = result[0]?.state;

  return value ? (JSON.parse(value) as T) : defaultValue;
}

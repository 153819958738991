import { MenuItem } from '@chakra-ui/react';
import { FC } from 'react';
import SignOutIcon from './assets/signOut.svg';

interface SignOutMenuItemProps {
  onClick: () => void;
}

export const SignOutMenuItem: FC<SignOutMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem
      onClick={onClick}
      data-testid="signOutButton"
      iconSpacing="4px"
      icon={<SignOutIcon />}
    >
      Sign Out
    </MenuItem>
  );
};

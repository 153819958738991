import { useFailureNotification } from './useFailureNotification';
import { useEffect } from 'react';

export function useCloseNotifications() {
  const { closeFailureNotifications } = useFailureNotification();

  useEffect(() => {
    closeFailureNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

import { FC } from 'react';
import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { downloadCodebook } from 'utils';
import { DownloadIcon } from './assets';
import { useFailureNotification, formatErrorMessages } from '@vizgen/vizgen-ui';

interface DownloadCodebookProps {
  panelId: string;
  hasCodebook: boolean;
  serialNumberTag: string;
}

export const DownloadCodebook: FC<DownloadCodebookProps> = ({
  panelId,
  hasCodebook,
  serialNumberTag
}) => {
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  const download = async () => {
    closeFailureNotifications();
    try {
      await downloadCodebook(panelId);
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <Button
      isDisabled={!hasCodebook && !serialNumberTag}
      variant="primaryGhost"
      maxW="166px"
      minW="166px"
      justifyContent="left"
      p="0 12px"
      borderRadius="0"
      onClick={download}
      data-testid={`downloadCodebookButton_${panelId}`}
    >
      <DownloadIcon />
      <Text as="span" ml="4px" color="content.link.normal" fontWeight="heavy">
        Codebook
      </Text>
    </Button>
  );
};

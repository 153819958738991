import { useQueryClient } from 'react-query';
import {
  useAddressesStore,
  useCreatePanelStore,
  useNewOrderStore,
  useResetPasswordStore
} from 'store';

export const useClearCache = () => {
  const queryClient = useQueryClient();
  const clearResetPasswordState = useResetPasswordStore(
    (state) => state.clearState
  );
  const clearAddressesState = useAddressesStore((state) => state.clearState);
  const clearNewOrderState = useNewOrderStore((state) => state.clearState);
  const clearCreatePanelState = useCreatePanelStore(
    (state) => state.clearState
  );

  const reset = () => {
    queryClient.clear();
    clearResetPasswordState();
    clearAddressesState();
    clearNewOrderState();
    clearCreatePanelState();
  };

  return reset;
};

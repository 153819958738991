import { Box, Flex, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { theme } from '../../../../../../theme';
import { SortTriangleIcon } from './components';
const { colors } = theme;

interface SortIconProps {
  isHovered: boolean;
  isSorted: boolean;
  isSortedDesc?: boolean;
}

export const SortIcon: FC<SortIconProps> = ({
  isHovered,
  isSorted,
  isSortedDesc
}) => {
  if (!isHovered && !isSorted) {
    return null;
  }

  if (isHovered && !isSorted) {
    return (
      <VStack
        spacing="6px"
        as="span"
        minW="24px"
        minH="24px"
        align="center"
        justify="center"
      >
        <Box as="span" transform="rotate(180deg)">
          <SortTriangleIcon color={colors.content.additional} />
        </Box>
        <Box as="span">
          <SortTriangleIcon color={colors.content.additional} />
        </Box>
      </VStack>
    );
  }

  return (
    <Flex align="center" justify="center" as="span" minW="24px" minH="24px">
      <Flex as="span" transform={isSortedDesc ? 'rotate(180deg)' : undefined}>
        <SortTriangleIcon color={colors.content.secondary} />
      </Flex>
    </Flex>
  );
};

import { Button, Center, Text, VStack } from '@chakra-ui/react';
import {
  formatErrorMessages,
  MiniModal,
  ModalErrorMessage
} from '@vizgen/vizgen-ui';
import { useInvalidateListFeatureTogglesQuery } from 'api/hooks';
import { FC, useState } from 'react';
import { ErrorIcon } from './assets';

interface ChemistryVersionErrorModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ModalComponent: FC<
  Omit<ChemistryVersionErrorModalProps, 'isVisible'>
> = ({ onClose, onSubmit }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const invalidateListFeatureToggles = useInvalidateListFeatureTogglesQuery();

  const onClick = async () => {
    try {
      setError('');
      setIsLoading(true);
      await invalidateListFeatureToggles();
      onSubmit();
      onClose();
      setIsLoading(false);
    } catch (e) {
      setError(formatErrorMessages(e));
    }
  };

  return (
    <MiniModal
      isInProgress={isLoading}
      title="MERFISH 1.0 chemistry is disabled"
      contentProps={{
        maxW: '490px'
      }}
      renderFooter={() => (
        <Center w="100%">
          <Button
            variant="secondarySolid"
            data-testid="confirmButton"
            isLoading={isLoading}
            onClick={onClick}
          >
            Ok
          </Button>
        </Center>
      )}
      onClose={onClose}
    >
      <VStack spacing="12px">
        <ErrorIcon />
        <Text>
          Creation of MERFISH 1.0 panels is disabled. Chemistry version will be
          changed to MERFISH 2.0.
        </Text>
        <ModalErrorMessage>{error}</ModalErrorMessage>
      </VStack>
    </MiniModal>
  );
};

export const ChemistryVersionErrorModal: FC<
  ChemistryVersionErrorModalProps
> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};

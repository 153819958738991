interface Extra {
  extraType: string;
  extraName: string;
  [key: string]: any;
}

export const findImagingKit = (extras: Extra[], genes: number) => {
  return (
    extras
      .filter((extra) => extra.extraType === 'Imaging Kit')
      .map((extra) => +extra.extraName.split(' ')[1])
      .sort((a, b) => a - b)
      .find((kit) => kit >= genes) || 0
  );
};

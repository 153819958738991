const smSwitchTrackSize = {
  '--switch-track-width': '30px',
  '--switch-track-height': '16px'
};
const xlSwitchTrackSize = {
  '--switch-track-width': '40px',
  '--switch-track-height': '24px'
};

export const Switch = {
  sizes: {
    md: {
      container: {
        width: '32px',
        height: '16px',
        ...smSwitchTrackSize
      },
      track: {
        boxSizing: 'border-box',
        border: '2px solid',
        borderColor: 'content.additional',
        bg: 'content.additional',
        padding: '0',
        _checked: {
          borderColor: 'state.success',
          bg: 'state.success'
        },
        ...smSwitchTrackSize
      },
      thumb: {
        ...smSwitchTrackSize,
        '--switch-track-height': '12px'
      }
    },
    xl: {
      container: {
        width: '40px',
        height: '24px',
        ...xlSwitchTrackSize
      },
      track: {
        boxSizing: 'border-box',
        border: '2px solid',
        borderColor: 'content.additional',
        bg: 'content.additional',
        padding: '0',
        _checked: {
          borderColor: 'state.success',
          bg: 'state.success'
        },
        ...xlSwitchTrackSize
      },
      thumb: {
        ...xlSwitchTrackSize,
        '--switch-track-height': '20px'
      }
    }
  }
};

import { IconButton, useClipboard } from '@chakra-ui/react';
import { FC } from 'react';
import CopyIcon from './assets/copy.svg';
import { theme } from '../../theme';
const { colors } = theme;

interface ClipboardButtonProps {
  value: string;
  onClick?: () => void;
}

export const ClipboardButton: FC<ClipboardButtonProps> = ({
  value,
  onClick
}) => {
  const { onCopy } = useClipboard(value);

  const copy = () => {
    onClick?.();
    onCopy();
  };

  return (
    <IconButton
      aria-label="Copy to clipboard"
      variant="primaryGhost"
      size="auto"
      _hover={{
        path: {
          stroke: colors.content.primary
        }
      }}
      icon={<CopyIcon />}
      onClick={copy}
    />
  );
};

import {
  Flex,
  Heading,
  Center,
  Button,
  VStack,
  StackDivider
} from '@chakra-ui/react';
import { Paths } from 'routes';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ResourceItem } from 'components';
import { useResourcesQuery } from 'api/hooks';
import { Category, TablePlaceholder } from '@vizgen/vizgen-ui';

export const Resources: FC = () => {
  const { data = [], isLoading } = useResourcesQuery();
  const placeholder = isLoading
    ? 'Loading...'
    : data.length
    ? ''
    : 'No resources found';

  const resources = data
    .filter((resource) => resource.category !== Category.Software)
    .slice(0, 3);

  return (
    <Flex direction="column" mt="24px">
      <Heading variant="headline" mb="24px">
        Resources
      </Heading>
      <VStack
        spacing="0"
        divider={<StackDivider borderColor="border.divider" />}
      >
        {placeholder ? (
          <TablePlaceholder>{placeholder}</TablePlaceholder>
        ) : (
          resources.map((resource) => (
            <ResourceItem
              key={resource.resourceId}
              resource={resource}
              isSoftware={false}
              isCompact
            />
          ))
        )}
      </VStack>
      <Center>
        <Button
          variant="primaryGhost"
          w="216px"
          h="40px"
          mt="24px"
          as={RouterLink}
          to={Paths.Resources}
          data-testid="allResourcesLink"
        >
          View All Resources
        </Button>
      </Center>
    </Flex>
  );
};

import { listFeatureTogglesQuery } from 'api';
import { ListFeatureTogglesQueryVariables } from 'generated/types';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const LIST_LIST_FEATURE_TOGGLES_QUERY_KEY = 'listFeatureToggles';

export function useListFeatureTogglesQuery(
  input: ListFeatureTogglesQueryVariables = {}
) {
  return useQuery(
    [LIST_LIST_FEATURE_TOGGLES_QUERY_KEY],
    async () => {
      const result = await listFeatureTogglesQuery(input);

      return result;
    },
    { refetchOnWindowFocus: false }
  );
}

export function useInvalidateListFeatureTogglesQuery() {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries([LIST_LIST_FEATURE_TOGGLES_QUERY_KEY]);
  }, [queryClient]);
}

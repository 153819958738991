import { FC } from 'react';
import { HStack, MenuList, MenuItem, Menu, Box } from '@chakra-ui/react';
import { TermsOfUseIcon, EditIcon } from './assets';
import { Link as BrowserLink, useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import { ProfileButton, SignOutMenuItem } from '@vizgen/vizgen-ui';
import { useClearCache } from 'hooks';
import { useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { UserProperties } from 'api';

export const UserMenu: FC = () => {
  const clearCache = useClearCache();
  const navigate = useNavigate();
  const [attributes, signOut] = useUserStore(
    (state) => [state.attributes || {}, state.signOut],
    shallow
  );
  const { name = '', familyName = '' } = (attributes || {}) as UserProperties;
  const username = `${name} ${familyName}`.trim() || 'Profile';

  const onSignOut = () => {
    signOut();
    clearCache();
    navigate(Paths.Home);
  };

  return (
    <Menu>
      <ProfileButton
        name={username}
        textBoxProps={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'initial'
          },
          textAlign: 'left'
        }}
      />
      <MenuList>
        <MenuItem
          as={BrowserLink}
          to={`${Paths.Profile}`}
          data-testid="profilePageLink"
        >
          <HStack spacing="4px">
            <Box>
              <EditIcon />
            </Box>
            <Box>Edit Profile</Box>
          </HStack>
        </MenuItem>
        <MenuItem
          as={BrowserLink}
          to={`${Paths.TermsOfUse}`}
          data-testid="termsOfUseLink"
        >
          <HStack spacing="4px">
            <Box>
              <TermsOfUseIcon />
            </Box>
            <Box>EULA</Box>
          </HStack>
        </MenuItem>
        <SignOutMenuItem onClick={onSignOut} />
      </MenuList>
    </Menu>
  );
};

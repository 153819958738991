import { Auth } from 'aws-amplify';
import {
  CreatePanelInput,
  CreatePanelTranscriptsFromGenesInput,
  DeletePanelTranscriptMutationVariables,
  GetPanelQueryVariables,
  ListPanelTranscriptsQueryVariables,
  ListQuotesQueryVariables,
  PanelTranscriptType,
  UpdatePanelInput,
  UpdatePanelTranscriptMutationVariables,
  CreateQuoteInput,
  GetQuoteQueryVariables,
  CreateQuotePositionInput,
  CreateQuotePositionMutation,
  FinalizePanelMutationVariables,
  FinalizePanelMutation,
  CreateAddressInput,
  ListAddresssQueryVariables,
  DeleteAddressInput,
  ListFinalizedPanelsByTissueQueryVariables,
  AddPromotedPanelToPanelMutationVariables,
  AddPromotedPanelToPanelMutation,
  AddFinalizedPanelToPanelMutation,
  AddFinalizedPanelToPanelMutationVariables,
  CreateExogenousSequenceInput,
  CreateExogenousSequenceMutation,
  CreateExogenousSequenceMutationVariables,
  ListExogenousSequencesQuery,
  ListExogenousSequencesQueryVariables,
  GetPanelsByOwnerQueryVariables,
  AddExogenousSequenceToPanelMutation,
  AddExogenousSequenceToPanelMutationVariables,
  ListPanelTranscriptsBySourceQuery,
  ListPanelTranscriptsBySourceQueryVariables,
  ListFileResourcesQuery,
  ListFileResourcesQueryVariables,
  UserPropsInput,
  UpdateUserPropsMutation,
  UpdateUserPropsMutationVariables,
  ListCountriesQuery,
  ListRegionsQuery,
  ListRegionsQueryVariables,
  GetUserPropsQuery,
  GetUserPropsQueryVariables,
  ApproveAccountMutation,
  ApproveAccountMutationVariables,
  PanelTranscriptSequentialAuxProbes,
  GetPanelsWithGenesByOwnerQueryVariables,
  DeleteGeneBlacklistItemMutation,
  DeleteGeneBlacklistItemMutationVariables,
  ListInstrumentsQuery,
  ListInstrumentsQueryVariables,
  Instrument,
  ListUserInstrumentNotificationsQuery,
  ListUserInstrumentNotificationsQueryVariables,
  UpdateUserInstrumentNotificationsMutation,
  UpdateUserInstrumentNotificationsMutationVariables,
  RemoveInstrumentUserMutation,
  RemoveInstrumentUserMutationVariables,
  InstrumentUserInput,
  AddInstrumentUserMutation,
  AddInstrumentUserMutationVariables,
  ListExtrasQueryVariables,
  ListExtrasQuery,
  GetCurrentGeneSetCollectionQuery,
  GetCurrentGeneSetCollectionQueryVariables,
  GetPanelUserViewQuery,
  GetPanelUserViewQueryVariables,
  CreatePanelUserViewMutation,
  CreatePanelUserViewMutationVariables,
  UpdatePanelUserViewInput,
  UpdatePanelUserViewMutation,
  UpdatePanelUserViewMutationVariables,
  DeletePanelTranscriptsBatchMutation,
  DeletePanelTranscriptsBatchMutationVariables,
  ListPromotedPanelsQuery,
  ListPromotedPanelsQueryVariables,
  Panel,
  GetPromotedPanelQuery,
  GetPromotedPanelQueryVariables,
  PanelOwnershipType,
  ModelPanelFilterInput,
  ListPromotedPanelsWithGenesQuery,
  ListPromotedPanelsWithGenesQueryVariables,
  ListPromotedPanelTranscriptsQuery,
  ListPromotedPanelTranscriptsQueryVariables,
  ListTissuesQuery,
  ListTranscriptomesQueryVariables,
  ListTranscriptomesQuery,
  ListFeatureTogglesQuery,
  ListFeatureTogglesQueryVariables,
  FeatureToggle,
  GetPresignedURLForCodebookQuery,
  GetPresignedURLForCodebookQueryVariables
} from 'generated/types';
import { ToggleTranscriptPanelModel } from 'store';
import { splitArrayByChunks } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import {
  updatePanelMutation,
  getPanelQuery,
  createPanelMutation,
  createPanelTranscriptsFromGenesMutation,
  listPanelTranscriptsQuery,
  listQuotesQuery,
  updatePanelTranscriptMutation,
  deletePanelTranscriptMutation,
  listExtrasQuery,
  getQuoteQuery,
  finalizePanelMutation,
  createQuoteMutation,
  createQuotePositionMutation,
  createAddressMutation,
  listAddressesQuery,
  deleteAddressMutation,
  listFinalizedPanelsByTissue,
  addPromotedPanelToPanelMutation,
  addFinalizedPanelToPanelMutation,
  createExogenousSequence,
  listExogenousSequences,
  getPanelsByOwnerQuery,
  addExogenousSequenceToPanel,
  listPanelTranscriptsBySource,
  listFileResources,
  updateUserProps,
  listCountries,
  listRegions,
  getUserProps,
  approveAccount,
  getPanelsWithGenesByOwnerQuery,
  deleteGeneBlacklistItem,
  listInstruments,
  listUserInstrumentNotifications,
  updateUserInstrumentNotifications,
  removeInstrumentUser,
  addInstrumentUser,
  getCurrentGeneSetCollection,
  getPanelUserView,
  createPanelUserView,
  updatePanelUserView,
  deletePanelTranscriptsBatch,
  listPromotedPanels,
  getPromotedPanelQuery,
  getPromotedPanelsWithGenesQuery,
  listPromotedPanelTranscriptsQuery,
  listTranscriptomes,
  listTissues,
  listFeatureToggles,
  getPresignedURLForCodebookQuery
} from './queries';
import {
  CreatePanel,
  CreatePanelTranscriptsFromGenes,
  DeletePanelTranscript,
  ListPanelTranscripts,
  QuotesList,
  UpdatePanel,
  UpdatePanelTranscript,
  CreateQuote,
  GetQuote,
  CreateAddress,
  ListAddresses,
  DeleteAddress,
  GetPanel,
  ListFinalizedPanelsByTissue,
  GetPanelsByOwner,
  UserProperties,
  GetPanelsWithGenesByOwner
} from './types';
import { executeQuery, executeMutation, executeChainQuery } from './utils';

const PANELS_BY_OWNER_DEFAULT_FILTER: ModelPanelFilterInput = {
  ownershipType: {
    ne: PanelOwnershipType.promoted
  }
};

export const getTissueListQuery = async () => {
  return (
    (await executeChainQuery<ListTissuesQuery>(listTissues, {}, 'listTissues'))
      .listTissues || []
  );
};

export const getTranscriptomeListQuery = async (
  transcriptomeIds: string[] | null
) => {
  return (
    (
      await executeQuery<
        ListTranscriptomesQuery,
        ListTranscriptomesQueryVariables
      >(listTranscriptomes, {
        transcriptomeIds
      })
    ).listTranscriptomes || []
  );
};

export const getPanelsByOwner = async (
  input: GetPanelsByOwnerQueryVariables
) => {
  const panels =
    (
      await executeChainQuery<GetPanelsByOwner, GetPanelsByOwnerQueryVariables>(
        getPanelsByOwnerQuery,
        {
          ...input,
          filter: {
            ...PANELS_BY_OWNER_DEFAULT_FILTER,
            ...input.filter
          }
        },
        'listPanelsByOwner'
      )
    ).listPanelsByOwner.items || [];
  return panels.map((panel) => ({
    ...panel,
    ownershipType: panel.ownershipType || PanelOwnershipType.proprietary
  })) as Panel[];
};

export const getPanelsWithGenesByOwner = async (
  input: GetPanelsWithGenesByOwnerQueryVariables
) => {
  const panels =
    (
      await executeChainQuery<
        GetPanelsWithGenesByOwner,
        GetPanelsWithGenesByOwnerQueryVariables
      >(
        getPanelsWithGenesByOwnerQuery,
        {
          ...input,
          filter: {
            ...PANELS_BY_OWNER_DEFAULT_FILTER,
            ...input.filter
          }
        },
        'listPanelsByOwner'
      )
    ).listPanelsByOwner.items || [];
  return panels.map((panel) => ({
    ...panel,
    ownershipType: panel.ownershipType || PanelOwnershipType.proprietary
  })) as Panel[];
};

export const getPromotedPanelsWithGenes = async (
  input: ListPromotedPanelsWithGenesQueryVariables
) => {
  return (
    (
      await executeChainQuery<
        ListPromotedPanelsWithGenesQuery,
        ListPromotedPanelsWithGenesQueryVariables
      >(getPromotedPanelsWithGenesQuery, input, 'listPromotedPanels')
    ).listPromotedPanels?.items || ([] as Panel[])
  );
};

export const getPanel = async (panelId: string) => {
  const panel = (
    await executeQuery<GetPanel, GetPanelQueryVariables>(getPanelQuery, {
      panelId
    })
  ).getPanel;
  return {
    ...panel,
    ownershipType: panel.ownershipType || PanelOwnershipType.proprietary
  };
};

export const createPanel = async (data: Omit<CreatePanelInput, 'panelId'>) => {
  const response = await executeMutation<CreatePanel, CreatePanelInput>(
    createPanelMutation,
    {
      ...data,
      panelId: uuidv4()
    }
  );
  return response?.createPanel;
};

export const updatePanel = async (data: UpdatePanelInput) => {
  const response = await executeMutation<UpdatePanel, UpdatePanelInput>(
    updatePanelMutation,
    data
  );

  return response?.updatePanel;
};

export const createPanelTranscriptsFromGenes = async ({
  geneInput,
  panelId,
  transcriptomeId,
  tissueId
}: CreatePanelTranscriptsFromGenesInput) => {
  const MAX_TRANSCRIPTS_AMOUNT = 500;
  const chunks = splitArrayByChunks(geneInput, MAX_TRANSCRIPTS_AMOUNT);
  const promises = chunks.map((chunk) =>
    executeMutation<
      CreatePanelTranscriptsFromGenes,
      CreatePanelTranscriptsFromGenesInput
    >(createPanelTranscriptsFromGenesMutation, {
      geneInput: chunk,
      panelId,
      transcriptomeId,
      tissueId
    })
  );
  const results = await Promise.all(promises);
  return results
    .flatMap((r) => r?.createPanelTranscriptsFromGenes || [])
    .flatMap((r) => r?.run || []);
};

export const createAddress = async (data: CreateAddressInput) => {
  const response = await executeMutation<CreateAddress, CreateAddressInput>(
    createAddressMutation,
    data
  );
  return response;
};

export const deleteAddress = async (addressId: string) => {
  const response = await executeMutation<DeleteAddress, DeleteAddressInput>(
    deleteAddressMutation,
    {
      addressId: addressId
    }
  );
  return response;
};

export const getListPanelTranscripts = async (
  panelId: string,
  input: ListPanelTranscriptsQueryVariables = {}
) => {
  return (
    (
      await executeChainQuery<
        ListPanelTranscripts,
        ListPanelTranscriptsQueryVariables
      >(
        listPanelTranscriptsQuery,
        {
          panelId,
          ...input
        },
        'listPanelTranscripts'
      )
    ).listPanelTranscripts.items || []
  );
};

export const getListPromotedPanelTranscripts = async (
  input: ListPromotedPanelTranscriptsQueryVariables
) => {
  return (
    (
      await executeChainQuery<
        ListPromotedPanelTranscriptsQuery,
        ListPromotedPanelTranscriptsQueryVariables
      >(
        listPromotedPanelTranscriptsQuery,
        input,
        'listPromotedPanelTranscripts'
      )
    ).listPromotedPanelTranscripts?.items || []
  );
};

export const getQuotesList = async (limit?: number) => {
  const input: ListQuotesQueryVariables = {};
  if (limit) {
    input['limit'] = limit;
  }

  const response = await executeChainQuery<
    QuotesList,
    ListQuotesQueryVariables
  >(listQuotesQuery, input, 'listQuotes');
  return response;
};

export const changeTranscriptPanel = async (
  panelId: string,
  transcript: ToggleTranscriptPanelModel,
  availableAuxProbes: PanelTranscriptSequentialAuxProbes[]
) => {
  return (
    await executeQuery<
      UpdatePanelTranscript,
      UpdatePanelTranscriptMutationVariables
    >(updatePanelTranscriptMutation, {
      input: {
        panelId,
        transcriptId: transcript.transcriptId,
        type:
          transcript.type === PanelTranscriptType.primary
            ? PanelTranscriptType.sequential
            : PanelTranscriptType.primary,
        auxProbes:
          transcript.type === PanelTranscriptType.primary
            ? availableAuxProbes.shift()
            : null
      }
    })
  ).updatePanelTranscript;
};

export const includeInsufficientTranscript = async (
  panelId: string,
  transcriptId: string,
  excludeMode?: boolean
) => {
  return (
    await executeQuery<
      UpdatePanelTranscript,
      UpdatePanelTranscriptMutationVariables
    >(updatePanelTranscriptMutation, {
      input: {
        panelId,
        transcriptId: transcriptId,
        isIncluded: excludeMode ? false : true
      }
    })
  ).updatePanelTranscript;
};

export const updatePanelTranscript = async (
  panelId: string,
  transcript: ToggleTranscriptPanelModel
) => {
  return (
    await executeQuery<
      UpdatePanelTranscript,
      UpdatePanelTranscriptMutationVariables
    >(updatePanelTranscriptMutation, {
      input: {
        panelId,
        ...transcript
      }
    })
  ).updatePanelTranscript;
};

export const deletePanelTranscript = async (
  panelId: string,
  transcriptId: string
) => {
  return (
    await executeQuery<
      DeletePanelTranscript,
      DeletePanelTranscriptMutationVariables
    >(deletePanelTranscriptMutation, {
      input: {
        panelId,
        transcriptId
      }
    })
  ).deletePanelTranscript;
};

export const deletePanelTranscripts = async (
  panelId: string,
  transcriptIds: string[]
) => {
  const MAX_TRANSCRIPTS_AMOUNT = 25;
  const payload = transcriptIds.map((transcriptId) => ({
    panelId,
    transcriptId
  }));
  const chunks = splitArrayByChunks(payload, MAX_TRANSCRIPTS_AMOUNT);
  const promises = chunks.map((chunk) =>
    executeMutation<
      DeletePanelTranscriptsBatchMutation,
      DeletePanelTranscriptsBatchMutationVariables
    >(deletePanelTranscriptsBatch, { input: chunk }, true)
  );
  const results = await Promise.all(promises);
  return results.flatMap((r) => r?.DeletePanelTranscriptsBatch || []);
};

export const getListExtras = async (extraType?: string) => {
  return (
    (
      await executeQuery<ListExtrasQuery, ListExtrasQueryVariables>(
        listExtrasQuery,
        { extraType }
      )
    ).listExtras || []
  );
};

export const getQuote = async (quoteId: string) => {
  return (
    await executeQuery<GetQuote, GetQuoteQueryVariables>(getQuoteQuery, {
      quoteId
    })
  ).getQuote;
};

export const createQuote = async (input: CreateQuoteInput) => {
  return await executeMutation<CreateQuote, CreateQuoteInput>(
    createQuoteMutation,
    {
      ...input
    }
  );
};

export const createQuotePosition = async (input: CreateQuotePositionInput) => {
  return await executeMutation<
    CreateQuotePositionMutation,
    CreateQuotePositionInput
  >(createQuotePositionMutation, {
    ...input
  });
};

export const finalizePanel = async (panelId: string) => {
  return (
    await executeMutation<
      FinalizePanelMutation,
      FinalizePanelMutationVariables
    >(
      finalizePanelMutation,
      {
        panelId
      },
      true
    )
  )?.finalizePanel;
};

export const getListAddresses = async (
  input: ListAddresssQueryVariables = {}
) => {
  return executeChainQuery<ListAddresses, ListAddresssQueryVariables>(
    listAddressesQuery,
    input,
    'listAddresss'
  );
};

export const getFinalizedPanelsByTissue = async (
  input: ListFinalizedPanelsByTissueQueryVariables
) => {
  const panels = (
    await executeQuery<
      ListFinalizedPanelsByTissue,
      ListFinalizedPanelsByTissueQueryVariables
    >(listFinalizedPanelsByTissue, input)
  ).listFinalizedPanelsByTissue?.items;
  return panels
    .map((panel) => ({
      ...panel,
      ownershipType: panel.ownershipType || PanelOwnershipType.proprietary
    }))
    .filter(
      (panel) => panel.ownershipType === PanelOwnershipType.proprietary
    ) as Panel[];
};

export const addPromotedPanelToPanel = async (
  panelId: string,
  promotedPanelId: string
) => {
  const response =
    (
      await executeMutation<
        AddPromotedPanelToPanelMutation,
        AddPromotedPanelToPanelMutationVariables
      >(
        addPromotedPanelToPanelMutation,
        {
          panelId,
          promotedPanelId
        },
        true
      )
    )?.addPromotedPanelToPanel || [];
  const transcripts = ([] as any[]).concat(
    ...(response.map((genes) => genes.run) || [])
  );
  return transcripts;
};

export const addFinalizedPanelToPanel = async (
  panelId: string,
  finalizedPanelId: string
) => {
  const response =
    (
      await executeMutation<
        AddFinalizedPanelToPanelMutation,
        AddFinalizedPanelToPanelMutationVariables
      >(
        addFinalizedPanelToPanelMutation,
        {
          panelId,
          finalizedPanelId
        },
        true
      )
    )?.addFinalizedPanelToPanel || [];
  const transcripts = ([] as any[]).concat(
    ...(response.map((genes) => genes.run) || [])
  );
  return transcripts;
};

export const createExogenousSequenceMutation = async (
  input: CreateExogenousSequenceInput
) => {
  return (
    await executeMutation<
      CreateExogenousSequenceMutation,
      CreateExogenousSequenceMutationVariables
    >(createExogenousSequence, { input }, true)
  )?.createExogenousSequence;
};

export const listExogenousSequencesQuery = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser();
  const owner = attributes.sub;
  return await executeQuery<
    ListExogenousSequencesQuery,
    ListExogenousSequencesQueryVariables
  >(listExogenousSequences, { owner });
};

export const addExogenousSequenceToPanelMutation = async (
  input: AddExogenousSequenceToPanelMutationVariables
) => {
  return await executeMutation<
    AddExogenousSequenceToPanelMutation,
    AddExogenousSequenceToPanelMutationVariables
  >(addExogenousSequenceToPanel, input, true);
};

export const listPanelTranscriptsBySourceQuery = async (
  input: ListPanelTranscriptsBySourceQueryVariables
) => {
  return await executeQuery<
    ListPanelTranscriptsBySourceQuery,
    ListPanelTranscriptsBySourceQueryVariables
  >(listPanelTranscriptsBySource, input);
};

export const listFileResourcesQuery = async (
  input: ListFileResourcesQueryVariables
) => {
  return await executeChainQuery<
    ListFileResourcesQuery,
    ListFileResourcesQueryVariables
  >(listFileResources, input, 'listFileResources');
};

export const getUserPropsQuery = async (): Promise<UserProperties> => {
  return (
    await executeQuery<GetUserPropsQuery, GetUserPropsQueryVariables>(
      getUserProps
    )
  ).getUserProps!;
};

export const updateUserPropsMutation = async (
  userProps: UserPropsInput
): Promise<UserProperties> => {
  return (
    await executeMutation<
      UpdateUserPropsMutation,
      UpdateUserPropsMutationVariables
    >(updateUserProps, { userProps }, true)
  )?.updateUserProps!;
};

export const listCountriesQuery = async () => {
  const countries = (
    await executeChainQuery<ListCountriesQuery, null>(
      listCountries,
      null,
      'listCountries'
    )
  ).listCountries;
  return countries?.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const listRegionsQuery = async (props: ListRegionsQueryVariables) => {
  const regions = (
    await executeChainQuery<ListRegionsQuery, ListRegionsQueryVariables>(
      listRegions,
      props,
      'listRegions'
    )
  ).listRegions;
  return regions?.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const approveAccountMutation = async (approvalRequestId: string) => {
  return (
    await executeMutation<
      ApproveAccountMutation,
      ApproveAccountMutationVariables
    >(approveAccount, { approvalRequestId }, true, true)
  )?.approveAccount;
};

export const deleteGeneBlacklistItemMutation = async (id: string) => {
  return await executeMutation<
    DeleteGeneBlacklistItemMutation,
    DeleteGeneBlacklistItemMutationVariables
  >(deleteGeneBlacklistItem, { id }, true);
};

export const listInstrumentsQuery = async () => {
  return ((
    await executeChainQuery<
      ListInstrumentsQuery,
      ListInstrumentsQueryVariables
    >(listInstruments, {}, 'listInstruments')
  ).listInstruments?.items || []) as Instrument[];
};

export const listUserInstrumentNotificationsQuery = async (
  instrumentUniqueId: string
) => {
  return (
    (
      await executeQuery<
        ListUserInstrumentNotificationsQuery,
        ListUserInstrumentNotificationsQueryVariables
      >(listUserInstrumentNotifications, { instrumentUniqueId })
    ).listUserInstrumentNotifications || []
  );
};

export const updateUserInstrumentNotificationsMutation = async (
  input: UpdateUserInstrumentNotificationsMutationVariables
) => {
  return (
    await executeMutation<
      UpdateUserInstrumentNotificationsMutation,
      UpdateUserInstrumentNotificationsMutationVariables
    >(updateUserInstrumentNotifications, input, true)
  )?.updateUserInstrumentNotifications;
};

export const removeInstrumentUserMutation = async (
  input: InstrumentUserInput
) =>
  (
    await executeMutation<
      RemoveInstrumentUserMutation,
      RemoveInstrumentUserMutationVariables
    >(removeInstrumentUser, { input }, true)
  )?.removeInstrumentUser;

export const addInstrumentUserMutation = async (input: InstrumentUserInput) => {
  try {
    return (
      (
        await executeMutation<
          AddInstrumentUserMutation,
          AddInstrumentUserMutationVariables
        >(addInstrumentUser, { input }, true)
      )?.addInstrumentUser || ({} as Instrument)
    );
  } catch (err: any) {
    const EMAIL_NOT_FOUND_ERROR = 'Nothing changed';
    const isNothingChanged = err.errors.some(
      ({ message }: any) => message === EMAIL_NOT_FOUND_ERROR
    );
    if (isNothingChanged) {
      return null;
    }
  }
};

export const getCurrentGeneSetLibraryQuery = async (
  params: GetCurrentGeneSetCollectionQueryVariables
) => {
  return (
    await executeQuery<
      GetCurrentGeneSetCollectionQuery,
      GetCurrentGeneSetCollectionQueryVariables
    >(getCurrentGeneSetCollection, params)
  ).getCurrentGeneSetCollection;
};

export const getPanelUserViewQuery = async (
  params: GetPanelUserViewQueryVariables
) => {
  return (
    await executeQuery<GetPanelUserViewQuery, GetPanelUserViewQueryVariables>(
      getPanelUserView,
      params
    )
  ).getPanelUserView;
};

export const createPanelUserViewMutation = async (
  input: CreatePanelUserViewMutationVariables
) =>
  (
    await executeMutation<
      CreatePanelUserViewMutation,
      CreatePanelUserViewMutationVariables
    >(createPanelUserView, input, true)
  )?.createPanelUserView;

export const updatePanelUserViewMutation = async (
  input: UpdatePanelUserViewInput
) =>
  (
    await executeMutation<
      UpdatePanelUserViewMutation,
      UpdatePanelUserViewMutationVariables
    >(updatePanelUserView, { input }, true)
  )?.updatePanelUserView;

export const listPromotedPanelsQuery = async (tissueId?: string) =>
  ((
    await executeChainQuery<
      ListPromotedPanelsQuery,
      ListPromotedPanelsQueryVariables
    >(
      listPromotedPanels,
      {
        tissueId
      },
      'listPromotedPanels'
    )
  ).listPromotedPanels?.items || []) as Panel[];

export const getPromotedPanel = async (promotedPanelId: string) => {
  return ((
    await executeQuery<GetPromotedPanelQuery, GetPromotedPanelQueryVariables>(
      getPromotedPanelQuery,
      {
        promotedPanelId
      }
    )
  ).getPromotedPanel || {}) as Panel;
};

export const listFeatureTogglesQuery = async (
  input: ListFeatureTogglesQueryVariables = {}
) =>
  ((
    await executeChainQuery<
      ListFeatureTogglesQuery,
      ListFeatureTogglesQueryVariables
    >(listFeatureToggles, input, 'listFeatureToggles')
  ).listFeatureToggles?.items || []) as FeatureToggle[];

export const getPresignedURLForCodebook = async (panelId: string) => {
  return (
    await executeQuery<
      GetPresignedURLForCodebookQuery,
      GetPresignedURLForCodebookQueryVariables
    >(getPresignedURLForCodebookQuery, {
      panelId
    })
  ).getPresignedURLForCodebook?.url;
};
